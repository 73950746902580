/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import gif from "../../assets/images/workspace/upscale-gif.gif";
import { DecodeUrl } from "../../utils/apiCalls";
import MembershipSucessfull from "../../components/SubScriptionPopups/membershipSucessfull/MembershipSucessfull";
import AddonSucessfull from "../../components/SubScriptionPopups/AddonSuccessPopup/AddonSuccessPopup";
import { UserContext } from "../../store/UserDetailsStore";
import { UserSocketContext } from "../../store/UserSocketData";

const SuccessPage = () => {
  const { updateSocketData } = useContext(UserSocketContext);
  const location = useLocation();
  const navigate = useNavigate();
  const baseurl = window.location.origin;
  const [isLoader, setIsLoader] = useState(false);
  const [isConfirmMessage, setIsConfirmMessage] = useState("");
  const [isSubSuccessfull, setIsSubSuccessfull] = useState(false);
  const [isAddonSuccess, setIsAddonSuccess] = useState(false);
  const { getUserAllPrivilages } = useContext(UserContext);
  const lastProject = JSON.parse(localStorage.getItem("lastProjectGenerate"));

  useEffect(() => {
    handleSuccess();
    getUserAllPrivilages(lastProject?.teamId);
  }, []);

  const handleSuccess = () => {
    const data = {
      url: baseurl + location.pathname + location.search,
    };
    DecodeUrl((res) => {
      if (res.status === 200) {
        getUserAllPrivilages(lastProject?.teamId);
        setIsConfirmMessage(res?.data?.name);
        setIsSubSuccessfull(true);
      }
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    }, data);
  };

  const handleOpenCloseSuccess = () => {
    setIsSubSuccessfull(!isSubSuccessfull);
    navigate("/account");
  };

  const handleAddonSuccess = () => {
    setIsAddonSuccess(false);
    navigate("/account");
  };

  return (
    <div>
      {isSubSuccessfull && (
        <MembershipSucessfull
          handleOpenCloseSuccess={handleOpenCloseSuccess}
          isConfirmMessage={isConfirmMessage}
        />
      )}
      {isAddonSuccess && (
        <AddonSucessfull handleAddonSuccess={handleAddonSuccess} />
      )}
      {isLoader && <img className="loader" src={gif} alt="gif" />}
    </div>
  );
};

export default SuccessPage;
