/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import "./AiriBotStyles.scss";
import addicon from "../../assets/images/chatgpt/add-icon.png";
import entericon from "../../assets/images/chatgpt/enter-icon.png";
import trashicon from "../../assets/images/chatgpt/trash.png";
import trashiconh from "../../assets/images/chatgpt/trash-h.png";
import trashiconc from "../../assets/images/chatgpt/trash-c.png";
import airiicon from "../../assets/images/chatgpt/airi-icon.png";
import info from "../../assets/images/icons/alert-icon.png";
import cross from "../../assets/images/workspace/icons/cross-icon.png";
import downarrow from "../../assets/images/chatgpt/down-arrow.png";
import anim from "../../assets/images/workspace/upscale-gif.gif";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader";
import {
  AddUserChatRoom,
  DeleteUserChatRoom,
  GetChatMessages,
  GetUserChatMessages,
  GetUserChatRooms,
  SendChatMessage,
  UpdateUserChatRoomName,
} from "../../utils/apiCalls";
import { UserContext } from "../../store/UserDetailsStore";
import PLanEnd from "../../components/SubScriptionPopups/plan-end/PLanEnd";
import BotTooltip from "../../components/Tooltips/BotTooltip";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import { UserSocketContext } from "../../store/UserSocketData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { marked } from "marked";
import Typewriter from "typewriter-effect/dist/core";
import GraphemeSplitter from "grapheme-splitter";

const AiriBotComponent = () => {
  const token = localStorage.getItem("token");
  const lastProject = JSON.parse(localStorage.getItem("lastProjectGenerate"));
  const { t } = useTranslation();
  const { isLoginUserDetails, isPrivilagesDetails } = useContext(UserContext);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { updateSocketData } = useContext(UserSocketContext);
  const targetRef = useRef();
  const [isEditingRoomId, setIsEditingRoomId] = useState(null);
  const [isMessage, setIsMessage] = useState("");
  const [isMessageClean, setIsMessageClean] = useState("");
  const [isGetAllChatRooms, setIsGetAllChatRooms] = useState([]);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const [isSelectedChatRoom, setIsSelectedChatRoom] = useState(null);
  const [isDeleteChatRoom, setIsDeleteChatRoom] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [editingStates, setEditingStates] = useState(
    Array(isGetAllChatRooms.length).fill(false)
  );
  const [editingState, setEditingState] = useState(-1);
  const [isCurrentChatRoomMessages, setIsCurrentChatRoomMessages] = useState(
    []
  );
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [isMessageShow, setIsMessageShow] = useState("");
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [messageResponse, setMessageResponse] = useState(false);
  const [newChatCreated, isNewChatCreated] = useState();
  const [lastChatRoomId, setLastChatRoomId] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [chatRoomNameInput, setChatRoomNameInput] = useState("");
  const [newMessageDisplay, setNewMessageDisplay] = useState("");
  const [isGptActive, setIsGptActive] = useState(false);
  const [isGptActivePopup, setIsGptActivePopup] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const [hoverStatus, setHoverStatus] = useState({});
  const [hoveredElement, setHoveredElement] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [htmlContents, setHtmlContents] = useState({});

  useEffect(() => {
    if (isPrivilagesDetails?.GPTUsageRights === false) {
      setIsGptActive(true);
    }
  }, [isPrivilagesDetails]);

  const handleCloseAccessPopup = () => {
    setIsGptActivePopup(false);
  };

  useEffect(() => {
    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    const formattedDate = `${day < 10 ? `0${day}` : day}/${
      month < 10 ? `0${month}` : month
    }`;
    setCurrentDate(formattedDate);
  }, []);

  const scrollToBottom = () => {
    const targetElement = targetRef.current;
    if (targetElement) {
      const scrollOptions = {
        top: targetElement.scrollHeight,
        behavior: "smooth",
      };
      targetElement.scrollTo(scrollOptions);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const handleClick = () => {
    const targetElement = targetRef.current;

    if (targetElement) {
      targetElement.scrollTo({
        top: targetElement.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    const targetElement = targetRef.current;

    if (targetElement) {
      const divHeight = targetElement.scrollHeight;
      const scrollTop = targetElement.scrollTop;
      const shouldShowArrow =
        scrollTop + targetElement.clientHeight < divHeight;
      setShowScrollArrow(shouldShowArrow);
    }
  };

  useEffect(() => {
    const targetElement = targetRef.current;

    if (targetElement) {
      targetElement.addEventListener("scroll", handleScroll);
      return () => {
        targetElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleDoubleClick = (roomIndex) => {
    if (roomIndex === editingState) {
      return;
    }

    if (editingState !== -1) {
    }

    setEditingState(roomIndex);
    setChatRoomNameInput(isGetAllChatRooms[roomIndex].chatRoomName);
    setIsEditingRoomId(isGetAllChatRooms[roomIndex]?.chatRoomId);
  };

  const handleInputBlur = (roomIndex) => {
    const newEditingStates = [...editingStates];
    newEditingStates[roomIndex] = false;
    setEditingStates(newEditingStates);
  };

  const handleKeyPress = (e, roomIndex) => {
    if (e.key === "Enter") {
      const newEditingStates = [...editingStates];
      newEditingStates[roomIndex] = false;
      setEditingStates(newEditingStates);

      e.preventDefault();
      handleUpdateChatRoomName(
        isGetAllChatRooms[roomIndex].chatRoomId,
        chatRoomNameInput
      );
    }
  };

  const handleMessageChange = (e) => {
    if (isPrivilagesDetails?.GPTUsageRights === true) {
      const textarea = document.getElementById("messageTextarea");
      textarea.style.height = "none !important";
      let inputText = e.target.value;

      let engChars = 0;
      let chnChars = 0;
      const chinesePattern = /[\u4E00-\u9FFF]/;
      const specialCharPattern = /[~!@#$%^&*()_+[\]{};':"\\|<>,.?/]/;

      for (let i = 0; i < inputText.length; i++) {
        const char = inputText[i];
        if (char.match(chinesePattern)) {
          chnChars += 1;
        } else if (specialCharPattern.test(char)) {
          engChars += 2;
        } else {
          engChars += 1;
        }

        if (engChars + chnChars * 4 >= 10000) {
          inputText = inputText.slice(0, i);
          break;
        }
      }

      const cleanedMessage = inputText.replace(/\s+/g, " ").trim();
      setIsMessageClean(cleanedMessage);
      adjustTextareaHeight(e.target);

      const remainingChars = 0 + (engChars + chnChars * 4);

      setIsMessage(inputText);
      setIsMessageShow(inputText);
      setNewMessage(inputText);
      setRemaining(Math.max(0, remainingChars));
    } else {
      setIsGptActivePopup(true);
    }
  };

  useEffect(() => {
    if (isShowMessage === false) {
      setNewMessageDisplay(newMessage);
    }
  }, [newMessage, isShowMessage]);

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
    document.documentElement.style.setProperty(
      "--textarea-height",
      textarea.style.height
    );
  };

  useEffect(() => {
    getAllUserChatRooms();
  }, []);

  useEffect(() => {
    if (lastChatRoomId) {
      getAllUserChatRooms();
    }
  }, [lastChatRoomId]);

  const getAllUserChatRooms = () => {
    const teamId = lastProject?.teamId;
    const projectId = lastProject?.projectId;
    GetUserChatRooms(
      (res) => {
        if (res?.status === 200) {
          setIsGetAllChatRooms(res?.data);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      },
      teamId,
      projectId
    );
  };

  const handleCreateChatRoom = () => {
    if (isPrivilagesDetails?.GPTUsageRights === true) {
      const payload = {
        teamId: lastProject?.teamId,
        projectId: lastProject?.projectId,
      };
      AddUserChatRoom((res) => {
        if (res.status === 200) {
          setUploadedFiles([]);
          isNewChatCreated(res?.data);
          setSelectedChatRoom(res?.data);
          const textarea = document.getElementById("messageTextarea");
          textarea.style.height = "auto";
          textarea.focus();
          textarea.value = "";
          textarea.setSelectionRange(1, 1);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    } else {
      setIsGptActivePopup(true);
    }
  };

  const handleChatRoomClick = (room) => {
    setSelectedChatRoom(room);
    setIsMessage("");
    setUploadedFiles([]);
    const textarea = document.getElementById("messageTextarea");
    textarea.style.height = "auto";
    textarea.value = " ";
    textarea.setSelectionRange(1, 1);
  };

  useEffect(() => {
    if (isEditingRoomId !== null) {
      if (isEditingRoomId !== selectedChatRoom?.chatRoomId) {
        setEditingState(-1);
      }
    }
  }, [isEditingRoomId, selectedChatRoom]);

  const handleCloseDeletePopup = () => {
    setDeletePopup(false);
  };

  const handleDeletePopup = (room) => {
    setDeletePopup(true);
    setIsSelectedChatRoom(room);
    setIsDeleteChatRoom(room);
  };

  const handleDeleteChatRoom = () => {
    setDeletePopup(false);
    setIsLoader(true);
    DeleteUserChatRoom((res) => {
      if (res?.status === 200) {
        setIsLoader(false);
        setShowConfirmModal(true);
        getAllUserChatRooms();
      }
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    }, isDeleteChatRoom?.chatRoomId);
  };

  const handleUpdateChatRoomName = (chatRoomId, isChatName) => {
    if (isPrivilagesDetails?.GPTUsageRights === true) {
      setIsLoader(true);
      UpdateUserChatRoomName(
        (res) => {
          if (res?.status === 200) {
            setEditingState(-1);
            setIsLoader(false);
            getAllUserChatRooms();
          }
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        },
        chatRoomId,
        isChatName
      );
    } else {
      setIsGptActivePopup(true);
    }
  };

  useEffect(() => {
    getChatRoomMessages();
  }, [selectedChatRoom?.chatRoomId]);

  const formatChatData = (data) => {
    return data?.map((chat) => {
      const date = new Date(chat?.createdDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const formatDate = `${day}/${month}`;

      return {
        ...chat,
        createdDate: formatDate,
        streaming: false,
      };
    });
  };

  const getChatRoomMessages = () => {
    if (selectedChatRoom?.chatRoomId) {
      if (isCurrentChatRoomMessages?.length !== 1) {
        setIsCurrentChatRoomMessages([]);
      }
      setMessageResponse(true);
      GetUserChatMessages(async (res) => {
        setMessageResponse(false);
        if (res?.status === 200) {
          if (res?.data?.length !== 0) {
            const data = await formatChatData(res?.data);
            setIsCurrentChatRoomMessages(data);
            setTimeout(() => {
              scrollToBottom();
            }, 500);
          } else {
            if (isCurrentChatRoomMessages?.length !== 1) {
              setIsCurrentChatRoomMessages([]);
            }
          }
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, selectedChatRoom?.chatRoomId);
    }
  };

  const handleKeyPressText = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setIsMessage(isMessage + "\n");
      setIsMessageShow(isMessage + "\n");
      setIsMessageClean(isMessageClean + "\n");
      const textarea = e.target;
      textarea.scrollTop = textarea.scrollHeight;
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
      // setIsMessageShow(isMessage + "\n");
    } else if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
      // setIsMessageShow(isMessage + "\n");
    }
  };

  const handleSendMessage = () => {
    if (isPrivilagesDetails?.GPTUsageRights === true) {
      if (newMessage !== "") {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const formatDate = `${day}/${month}`;
        const sentMessage = {
          isResponse: false,
          createdDate: formatDate,
          message: newMessage,
          file: uploadedFiles[0]?.fileUrl ?? null,
          streaming: false,
        };

        setIsCurrentChatRoomMessages((prevMessages) => [
          ...prevMessages,
          sentMessage,
        ]);

        setIsMessage("");
        const textarea = document.getElementById("messageTextarea");
        textarea.style.height = "auto";
        textarea.focus();
        textarea.value = "";
        textarea.setSelectionRange(1, 1);
        setRemaining(0);

        setIsShowMessage(true);

        setTimeout(() => {
          scrollToBottom();
        }, 500);

        const payload = {
          teamId: lastProject?.teamId,
          projectId: lastProject?.projectId,
        };

        if (!selectedChatRoom?.chatRoomId) {
          AddUserChatRoom((res) => {
            if (res?.status === 200) {
              isNewChatCreated(res?.data);
              setSelectedChatRoom(res?.data);
              handleSendChatMessage(res?.data?.chatRoomId);
            } else if (res?.message === "Invalid access token") {
              updateSocketData({ isExitPopup: true });
            }
          }, payload);
        } else {
          handleSendChatMessage(selectedChatRoom?.chatRoomId);
        }
      }
    } else {
      setIsGptActivePopup(true);
    }
  };

  const handleSendChatMessage = (chatRoomId) => {
    const payload = {
      chatRoomId,
      message: newMessage,
    };

    setUploadedFiles([]);
    setLastChatRoomId(chatRoomId);

    SendChatMessage(async (res) => {
      if (res?.status === 200) {
        const botResponse = await formatChatData(res?.data);
        let chat = botResponse[1];
        chat.streaming = true;
        setIsCurrentChatRoomMessages((prevMessages) => [...prevMessages, chat]);

        setIsShowMessage(false);
        setTimeout(() => {
          scrollToBottom();
        }, 200);
      } else if (res?.message === "Invalid access token") {
        updateSocketData({ isExitPopup: true });
      }
    }, payload);
  };

  useEffect(() => {
    if (newChatCreated !== undefined && newChatCreated !== null) {
      pushChatList([newChatCreated]);
    }
  }, [newChatCreated]);

  const pushChatList = (newChatCreated) => {
    const updatedChatRooms = [...newChatCreated, ...isGetAllChatRooms];
    setIsGetAllChatRooms(updatedChatRooms);
  };

  const handleMouseEnter = (event, index) => {
    setHoveredElement(event.currentTarget);
    setHoverStatus({ ...hoverStatus, [index]: true });
  };

  const handleMouseLeave = (index) => {
    setHoverStatus({ ...hoverStatus, [index]: false });
  };

  useEffect(() => {
    if (isCurrentChatRoomMessages?.length) {
      isCurrentChatRoomMessages.forEach((chat, index) => {
        if (chat.streaming && !htmlContents[index]) {
          const stringSplitter = (string) => {
            const splitter = new GraphemeSplitter();
            return splitter.splitGraphemes(string);
          };

          const typewriter = new Typewriter(null, {
            loop: false,
            delay: 15,
            stringSplitter,
            onCreateTextNode: (character) => {
              scrollToBottom();
              setHtmlContents((prev) => ({
                ...prev,
                [index]: (prev[index] || "") + character,
              }));
              return null;
            },
          });

          typewriter.typeString(chat?.message).start();
        } else {
          setHtmlContents((prev) => ({
            ...prev,
            [index]: chat?.message,
          }));
        }
      });
    }
  }, [isCurrentChatRoomMessages]);

  return (
    <UserPortalLayout>
      <div className="airi-bot">
        <div className="airi-bot__container">
          <div className="airi-bot__sections">
            <div className="airi-bot__sidenav">
              <div className="airi-bot__sidenav__container">
                <div
                  className="airi-bot__sidenav__sections"
                  data-scrollable="true"
                >
                  <div className="airi-bot__sidenav__header">
                    <h4>{t("gpt_heading")}</h4>
                  </div>
                  <div className="airi-bot__sidenav__button">
                    <button onClick={handleCreateChatRoom}>
                      <img src={addicon} alt="add-icon" />{" "}
                      <span>{t("new_chat")}</span>{" "}
                    </button>
                  </div>
                  <div className="airi-bot__sidenav__chats">
                    <div className="airi-bot__sidenav__chats__list">
                      {isGetAllChatRooms?.length ? (
                        isGetAllChatRooms?.map((room, index) => {
                          const isSelected =
                            selectedChatRoom?.chatRoomId === room.chatRoomId;
                          // const isCurrentlyEditing = editingStates[index];
                          const isCurrentlyEditing = index === editingState;
                          return (
                            <div
                              key={index}
                              className={`airi-bot__sidenav__chats__list__sec ${
                                isSelected ? "active" : ""
                              } ${isCurrentlyEditing === true ? "edit" : ""}`}
                              onClick={() => handleChatRoomClick(room)}
                            >
                              <div className="airi-bot__sidenav__chats__list__sec__input">
                                <div
                                  className={`airi-bot__sidenav__chats__list__sec__inputs ${
                                    isCurrentlyEditing === true ? "edit" : ""
                                  }`}
                                  onDoubleClick={() => handleDoubleClick(index)}
                                >
                                  {isCurrentlyEditing ? (
                                    <input
                                      ref={(input) => {
                                        if (input && editingState === index) {
                                          input.focus();
                                        }
                                      }}
                                      value={chatRoomNameInput}
                                      onChange={(e) =>
                                        setChatRoomNameInput(e.target.value)
                                      }
                                      onBlur={() => handleInputBlur(index)}
                                      onKeyPress={(e) =>
                                        handleKeyPress(e, index)
                                      }
                                    />
                                  ) : (
                                    <>
                                      {room.chatRoomName.length > 20 ? (
                                        <div
                                          className="airi-bot__sidenav__chats__list__sec__tooltip"
                                          onMouseEnter={(e) =>
                                            handleMouseEnter(e, index)
                                          }
                                          onMouseLeave={() =>
                                            handleMouseLeave(index)
                                          }
                                          key={index}
                                        >
                                          <h5>
                                            {isCurrentlyEditing
                                              ? chatRoomNameInput
                                              : room?.chatRoomName}
                                          </h5>
                                          {!responsiveData && (
                                            <BotTooltip
                                              triggerElement={hoveredElement}
                                              isVisible={hoverStatus[index]}
                                              TooltipContent={
                                                room?.chatRoomName
                                              }
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <h5>
                                          {isCurrentlyEditing
                                            ? chatRoomNameInput
                                            : room?.chatRoomName}
                                        </h5>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div
                                className="trash-icons"
                                onClick={() => handleDeletePopup(room)}
                              >
                                <img
                                  className="trash"
                                  src={trashicon}
                                  alt="trash-icon"
                                />
                                <img
                                  className="hover"
                                  src={trashiconh}
                                  alt="trash-icon"
                                />
                                <img
                                  className="click"
                                  src={trashiconc}
                                  alt="trash-icon"
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="airi-bot__main">
              <div className="airi-bot__main__section" data-scrollable="true">
                <div className="airi-bot__main__section__chat" ref={targetRef}>
                  {isCurrentChatRoomMessages?.length ? (
                    isCurrentChatRoomMessages?.map((chat, index) => {
                      const downloadLinkRegex =
                        /\[Download\s.*\]\(sandbox:.*\.[a-zA-Z0-9]+\)/;
                      const messageContent = htmlContents[index]
                        ?.replace(downloadLinkRegex, "")
                        ?.trim();
                      const htmlContent = marked(messageContent || "");
                      // const isFile = chat?.file !== null && chat?.file !== "";

                      // const imageExtensions = [
                      //   "jpeg",
                      //   "jpg",
                      //   "png",
                      //   "gif",
                      //   "webp",
                      // ];
                      // const isImageFile = isFile
                      //   ? imageExtensions.some((ext) =>
                      //       chat?.file?.toLowerCase().endsWith(ext)
                      //     )
                      //   : false;

                      // const splits = isFile ? chat?.file?.split(".") : null;

                      // const getFileName = (url) => {
                      //   const fullPath = new URL(url).pathname;
                      //   return fullPath.substring(
                      //     fullPath.lastIndexOf("_") + 1
                      //   );
                      // };
                      // const getFileName1 = (url) => {
                      //   return url.substring(url.lastIndexOf("/") + 1);
                      // };

                      // const fileName = isFile
                      //   ? chat?.isResponse
                      //     ? getFileName1(chat?.file)
                      //     : getFileName(chat?.file)
                      //   : null;

                      // const ext = splits && splits[splits?.length - 1];

                      const avatar =
                        chat?.avatarUrl ?? isLoginUserDetails?.avatarUrl;

                      return (
                        <div
                          className={`airi-bot__main__section__chat__container ${
                            chat?.isResponse === true ? "answer" : ""
                          }`}
                          key={index}
                        >
                          <div
                            className={`airi-bot__main__section__chat__container__data ${
                              chat?.isResponse === true ? "answer__data" : ""
                            }`}
                          >
                            <div
                              className={`airi-bot__main__section__chat__container__data__user ${
                                chat?.isResponse === true
                                  ? "answer__data__user"
                                  : ""
                              }`}
                            >
                              <div
                                className={`airi-bot__main__section__chat__container__data__user__top ${
                                  chat?.isResponse === true
                                    ? "answer__data__user__top"
                                    : ""
                                }`}
                              >
                                {chat?.isResponse === true ? (
                                  <img src={airiicon} alt="user-icon" />
                                ) : (
                                  <img src={avatar} alt="airi-icon" />
                                )}
                                {/* <p>{formatDate}</p> */}
                                <p>{chat?.createdDate}</p>
                              </div>
                            </div>
                            <div
                              className={`airi-bot__main__section__chat__container__data__content ${
                                chat?.isResponse === true
                                  ? "answer__data__content"
                                  : ""
                              }`}
                            >
                              {/* {isFile && (
                                <>
                                  {isImageFile ? (
                                    <div className="thumbnail">
                                      {thumbnails[chat.file] ? (
                                        <img
                                          src={
                                            thumbnails[chat.file]
                                              ? thumbnails[chat.file] || ""
                                              : chat?.file
                                          }
                                          alt="thumbnail"
                                          onClick={() =>
                                            handleOpenFilePopup(
                                              chat?.file,
                                              ext,
                                              fileName
                                            )
                                          }
                                        />
                                      ) : (
                                        <div className="thumbnail__loading"></div>
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      className={`airi-bot__main__section__chat__container__data__content__file ${
                                        chat?.isResponse === true
                                          ? "answer__data__content__file"
                                          : ""
                                      }`}
                                    >
                                      <Popover
                                        overlayClassName="file-popover"
                                        content={fileName}
                                        arrow={true}
                                        trigger="hover"
                                        placement="right"
                                        open={openPopovers[index]}
                                        onOpenChange={(isOpen) =>
                                          handlePopoverOpen(index, isOpen)
                                        }
                                      >
                                        <div
                                          key={index}
                                          className={`airi-bot__main__section__chat__container__data__content__file__section ${
                                            chat?.isResponse === true
                                              ? "answer__data__content__file__section"
                                              : ""
                                          }`}
                                        >
                                          <img
                                            src={getFileIcon(ext)}
                                            alt={`${ext}-icon`}
                                            className="file-icon"
                                            onClick={() =>
                                              handleOpenFilePopup(
                                                chat?.file,
                                                ext,
                                                fileName
                                              )
                                            }
                                          />
                                          <div
                                            className={`airi-bot__main__section__chat__container__data__content__file__section__name ${
                                              chat?.isResponse === true
                                                ? "answer__data__content__file__section__name"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleOpenFilePopup(
                                                chat?.file,
                                                ext,
                                                fileName
                                              )
                                            }
                                          >
                                            <span className="name">
                                              {fileName}
                                            </span>
                                            <span>{ext}</span>
                                          </div>
                                          <div
                                            className={`airi-bot__main__section__chat__container__data__content__file__section__download ${
                                              chat?.isResponse === true
                                                ? "answer__data__content__file__section__download"
                                                : ""
                                            }`}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <img
                                              className="download"
                                              src={download}
                                              alt="download-icon"
                                              onClick={() =>
                                                handleDownloadFile(
                                                  chat?.file,
                                                  fileName
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </Popover>
                                    </div>
                                  )}
                                </>
                              )} */}
                              <pre
                                dangerouslySetInnerHTML={{
                                  __html: htmlContent || "",
                                }}
                              ></pre>
                            </div>
                            <span></span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {isShowMessage && (
                    <div className="container">
                      <img src={anim} alt="user-icon" />
                    </div>
                  )}
                  {isCurrentChatRoomMessages?.length !== 1 &&
                    messageResponse && (
                      <div className="container">
                        <img src={anim} alt="user-icon" />
                      </div>
                    )}
                  {showScrollArrow && (
                    <div className="airi-bot__main__section__scroll">
                      <img
                        src={downarrow}
                        alt="down-arrow"
                        onClick={handleClick}
                      />
                    </div>
                  )}
                  <div
                    className={`airi-bot__main__section__empty ${
                      uploadedFiles?.length !== 0 ? "file" : ""
                    }`}
                  >
                    <div className="airi-bot__main__section__empty__container"></div>
                  </div>
                </div>
                <div
                  className={`airi-bot__main__section__bottom ${
                    uploadedFiles?.length !== 0 ? "file" : ""
                  }`}
                >
                  <div className="airi-bot__main__section__bottom__input">
                    <textarea
                      id="messageTextarea"
                      value={isMessage}
                      onChange={handleMessageChange}
                      onKeyPress={(e) => handleKeyPressText(e)}
                      rows={1}
                      maxrows={4}
                      draggable={false}
                      className="non-resizable-textarea"
                      placeholder={t("start_typing_here")}
                    />
                    <img
                      src={entericon}
                      alt="enter-icon"
                      onClick={handleSendMessage}
                    />
                    <p
                      className="count-remain"
                      style={{ color: remaining === 10000 && "red" }}
                    >
                      {remaining}
                      <span>/ 10000</span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isGptActive === true && (
            <>
              {isGptActivePopup === true && (
                <PLanEnd
                  handleCloseAccessPopup={handleCloseAccessPopup}
                  handleSubscription={handleCloseAccessPopup}
                  content={t("gpt_privillage")}
                  stringB={"Contact_us"}
                />
              )}
            </>
          )}
        </div>
        {deletePopup && (
          <div className="down" onClick={handleCloseDeletePopup}>
            <div className="down__popup">
              <div
                className="down__popup__container"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="down__popup__section">
                  <div className="down__popup__section__header">
                    <img src={info} alt="info-icon" />
                    <h3>
                      {t("delete")} {isDeleteChatRoom?.chatRoomName}
                    </h3>
                    <img
                      className="cross"
                      src={cross}
                      alt="cross-icon"
                      onClick={handleCloseDeletePopup}
                    />
                  </div>
                  <div className="down__popup__section__content">
                    <p>
                      {t("delete_chat_room_text1")}
                      {isDeleteChatRoom?.chatRoomName}{" "}
                      {t("delete_chat_room_text2")}
                    </p>
                  </div>
                  <div className="down__popup__section__buttons">
                    <div className="down__popup__section__buttons__section">
                      <button
                        onClick={handleCloseDeletePopup}
                        className="cancel"
                      >
                        {t("cancel")}
                      </button>
                      <button onClick={handleDeleteChatRoom}>
                        {t("delete")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showConfirmModal && (
          <div className="delete" onClick={handleCloseDeletePopup}>
            <div className="delete__popup">
              <div
                className="delete__popup__container"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="delete__popup__section">
                  <div className="delete__popup__section__content">
                    <p>
                      "{isSelectedChatRoom?.chatRoomName}"{" "}
                      {t("deleted_succesfully")}
                    </p>
                  </div>
                  <div className="delete__popup__section__buttons">
                    <div className="delete__popup__section__buttons__section">
                      <button
                        onClick={() => {
                          setShowConfirmModal(false);
                        }}
                      >
                        {t("ok")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoader && <Loader />}
      </div>
    </UserPortalLayout>
  );
};

export default AiriBotComponent;
