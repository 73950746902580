/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import "./TiresPopUp.scss";
import {
  GetCameraViewUrlUser,
  GetFirstTierUser,
  GetSecondTierUser,
  GetThirdTierAndStyleUser,
} from "../../../utils/apiCalls";
import arrow from "../../../assets/images/icons/next-arrow-icon.png";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import sunny from "../../../assets/images/workspace/atmosphere/sunny.png";
import { useTranslation } from "react-i18next";
import { DesignLibraryContext } from "../../../store/DesignLibraryData";
import loader from "../../../assets/images/workspace/upscale-gif.gif";

const TiresPopUp = () => {
  const { t } = useTranslation();
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const { designLibraryData, updateDesignLibraryData } =
    useContext(DesignLibraryContext);
  let dlData = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
  const tiresData = JSON.parse(
    localStorage.getItem(`designLibraryData_${lastPart}`)
  );
  const lang = localStorage.getItem("language");

  const [selectedType, setSelectedType] = useState(null);
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(1);
  const [selectedStyleUrl, setSelectedStyleUrl] = useState(null);
  const [isDesignLibraryId, setIsDesignLibraryId] = useState(
    dlData?.isDesignLibraryId
  );
  const [isFirstTier, setIsFirstTier] = useState(null);
  const [isSecondTier, setIsSecondTier] = useState(null);
  const [isThirdTierId, setIsThirdTierId] = useState(null);
  const [isFirstTierData, setIsFirstTierData] = useState(null);
  const [isSecondTierData, setIsSecondTierData] = useState(null);
  const [isThirdTierData, setIsThirdTierData] = useState(null);
  const [selectedFirstItemId, setSelectedFirstItemId] = useState(
    tiresData?.isFirstTierId || 1
  );
  const [selectedSecondItemId, setSelectedSecondItemId] = useState(
    tiresData?.isSecondTierId || 1
  );
  const [selectedThirdItemId, setSelectedThirdItemId] = useState(
    tiresData?.styleId || 1
  );
  const [isFirstTireNameSelect, setIsFirstTireNameSelect] = useState("");
  const [isSecondTireNameSelect, setIsSecondTireNameSelect] = useState("");
  const [isThirdTireNameSelect, setIsThirdTireNameSelect] = useState("");
  const [isStyleNameSelect, setIsStyleNameSelect] = useState("");
  const [isFirstTierLoading, setIsFirstTierLoading] = useState(false);
  const [isSecondTierLoading, setIsSecondTierLoading] = useState(false);
  const [isThirdTierLoading, setIsThirdTierLoading] = useState(false);
  const [isDisplayFirstTier, setIsDisplayFirstTier] = useState(false);
  const [isDisplaySecondTier, setIsDisplaySecondTier] = useState(false);

  useEffect(() => {
    if (designLibraryData?.isDisplayAlldata === true) {
      setIsDisplayFirstTier(true);
      setIsDisplaySecondTier(true);
    } else if (designLibraryData?.isDisplayAlldata === false) {
      setIsDisplayFirstTier(false);
      setIsDisplaySecondTier(false);
    }
  }, [designLibraryData]);

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(selectedThirdItemId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
    setTimeout(() => {
      const element = document.getElementById(selectedThirdItemId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 400);
    setTimeout(() => {
      const element = document.getElementById(selectedThirdItemId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 800);
    setTimeout(() => {
      const element = document.getElementById(selectedThirdItemId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1500);
  }, [selectedThirdItemId]);

  useEffect(() => {
    getFirstTierDesign();
  }, []);

  useEffect(() => {
    getSecondTierDesign();
  }, [selectedType]);

  useEffect(() => {
    getThirdTierDesign();
  }, [selectedClassification]);

  const getFirstTierDesign = () => {
    let dlData = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
    const payload = {
      designLibraryId:
        designLibraryData?.isDisplayAlldata === false
          ? isDesignLibraryId
          : dlData?.prevDesignLibraryId,
    };
    if (payload) {
      setIsFirstTierLoading(true);
      GetFirstTierUser((res) => {
        if (res?.status === 200) {
          setIsFirstTierData(res.data);
          setTimeout(() => {
            setIsFirstTierLoading(false);
          }, 400);
        } else if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const getSecondTierDesign = () => {
    setIsSecondTierLoading(true);
    let dlData = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));
    let check = JSON.parse(localStorage.getItem(`designData_${lastPart}`));
    const payload = {
      designLibraryId:
        designLibraryData?.isDisplayAlldata === false
          ? isDesignLibraryId
          : dlData?.prevDesignLibraryId,
      firstTierId: selectedFirstItemId || check?.isFirstTierId,
    };
    if (payload) {
      GetSecondTierUser((res) => {
        if (res?.status === 200) {
          setIsSecondTierData(res.data);
          setTimeout(() => {
            setIsSecondTierLoading(false);
          }, 400);
        } else if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const getThirdTierDesign = () => {
    setIsThirdTierLoading(true);
    let check = JSON.parse(localStorage.getItem(`designData_${lastPart}`));
    const payload = {
      secondTierId: selectedSecondItemId || check?.isSecondTierId,
    };
    if (payload) {
      GetThirdTierAndStyleUser((res) => {
        if (res?.status === 200 || res.length !== 0) {
          // all the objects sort as per priority 2 from top to bottom
          res?.data?.sort((a, b) => a.style[0].priority2 - b.style[0].priority2);
          // all the objects inside each style array sort as per priority from left to right
          res?.data?.forEach((item) => {
            item?.style?.sort((a, b) => a.priority - b.priority)
          });
          setIsThirdTierData(res?.data || res);
          setTimeout(() => {
            setIsThirdTierLoading(false);
          }, 400);
        } else if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const handleTypeClick = (type) => {
    if (type !== undefined) {
      setIsDisplayFirstTier(true);
      setIsDisplaySecondTier(false);
    }
    let check = JSON.parse(localStorage.getItem(`designData_${lastPart}`));
    setSelectedType(type?.id);
    setIsFirstTier(type);
    setIsFirstTireNameSelect(type?.name);
    setSelectedFirstItemId(type?.id || check?.isFirstTierId);
  };

  const handleClassificationClick = (second) => {
    if (second !== undefined) {
      setIsDisplaySecondTier(true);
    }
    setIsSecondTireNameSelect(second?.name);
    setSelectedClassification(second?.id);
    setIsSecondTier(second);
    setSelectedSecondItemId(second?.id);
  };

  const handleStyleClick = (style) => {
    if (
      (dlData?.prevDesignLibraryId === 30 &&
        dlData?.prevDesignLibraryId !== dlData?.isDesignLibraryId) ||
      (dlData?.isDesignLibraryId === 30 &&
        dlData?.prevDesignLibraryId !== dlData?.isDesignLibraryId)
    ) {
      getAtmosphere();
    }
    setIsDisplayFirstTier(false);
    setIsDisplaySecondTier(false);
    setIsThirdTireNameSelect(style?.tierThreeName);
    setIsStyleNameSelect(style?.name);
    setSelectedStyle(style?.id);
    setIsThirdTierId(style?.styleType);
    setSelectedThirdItemId(style?.id);
    setSelectedStyleUrl(style?.coverImageSuffix);
    let check = JSON.parse(
      localStorage.getItem(`designLibraryData_${lastPart}`)
    );
    if (check?.styleURL !== null && check?.styleURL !== undefined) {
      check.styleURL = style?.coverImageSuffix;
      check.styleId = style?.id;
      check.thirdTierSubName = style?.name;
      check.thirdTierSubNameCh = style?.alias;
      check.thirdTierName = style?.tierThreeName;
      check.thirdTierNameCh = style?.tierThreeNameAlias;
      check.isThirdTierId = style?.styleType;
      check.firstTierName = isFirstTier?.name || check.firstTierName;
      check.firstTierNameCh = isFirstTier?.alias || check.firstTierNameCh;
      check.isFirstTierId = selectedType || check.isFirstTierId;
      check.secondTierName = isSecondTireNameSelect || check.secondTierName;
      check.secondTierNameCh = isSecondTier?.alias || check.secondTierNameCh;
      check.isSecondTierId = selectedClassification || check.isSecondTierId;
    } else {
      const tiresData = {
        styleURL: style?.coverImageSuffix,
        styleId: style?.id,
        firstTierName: check.firstTierName,
        firstTierNameCh: isFirstTier?.alias || check.firstTierNameCh,
        isFirstTierId: check.isFirstTierId,
        secondTierName: check.secondTierName,
        secondTierNameCh: isSecondTier?.alias || check.secondTierNameCh,
        isSecondTierId: check.isSecondTierId,
        thirdTierName: style?.tierThreeName,
        thirdTierNameCh: style?.tierThreeNameAlias,
        thirdTierSubName: style?.name,
        thirdTierSubNameCh: style?.alias,
        isThirdTierId: style?.styleType,
      };
      localStorage.setItem(
        `designLibraryData_${lastPart}`,
        JSON.stringify(tiresData)
      );
    }
    if (check?.styleURL !== null && check?.styleURL !== undefined) {
      localStorage.setItem(
        `designLibraryData_${lastPart}`,
        JSON.stringify(check)
      );
    }
    let DesignData =
      JSON.parse(localStorage.getItem(`dlData_${lastPart}`)) || {};
    DesignData.prevDesignLibraryId = designLibraryData?.isDisplayAlldata ? DesignData.prevDesignLibraryId : DesignData.isDesignLibraryId;
    localStorage.setItem(`dlData_${lastPart}`, JSON.stringify(DesignData));
    handleCloseTiresPopup();
  };

  const handleCloseTiresPopup = () => {
    const newData = {
      isCloseTiresPopup: false,
    };
    updateDesignLibraryData(newData);
  };

  const getAtmosphere = () => {
    const designLibraryType = dlData?.isDesignLibraryId ?? 22;
    GetCameraViewUrlUser((res) => {
      if (res?.status === 200 && res?.data?.length !== 0) {
        const atmosphere = {
          defaultSelectedAtmosphereId: res?.data?.[0]?.id,
          defaultSelectedAtmosphereUrl: res?.data?.[0]?.thumbnail,
          seasonName: "sunny",
          seasonIcon: sunny,
          seasonNameSelected: "spring",
          seasonNameSelectedId: 1,
        };
        localStorage.setItem(
          `atmosphereData_${lastPart}`,
          JSON.stringify(atmosphere)
        );
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    }, designLibraryType);
  };

  return (
    <>
      <div className="background-tires" onClick={handleCloseTiresPopup}>
        <div className="tires" onClick={(e) => e.stopPropagation()}>
          <div className="tires__container">
            <div className="tires__disable-section"></div>
            <div className="tires__main">
              <div className="tires__main__close">
                <img
                  src={cross}
                  onClick={handleCloseTiresPopup}
                  alt="cross-icon"
                />
              </div>
              <div className="tires__main__section" data-scrollable="true">
                <h3>
                  {t("architecture")}{" "}
                  {selectedType ? `| ${isFirstTireNameSelect} ` : ""}
                  {selectedClassification ? `| ${isSecondTireNameSelect} ` : ""}
                  {selectedStyle
                    ? `| ${isThirdTireNameSelect} | ${isStyleNameSelect}`
                    : ""}
                </h3>
                <div
                  className={`tires__main__section__menu1 ${
                    selectedType ? "selected" : ""
                  }`}
                >
                  {!isFirstTierLoading ? (
                    <h4>
                      {designLibraryData?.isDisplayAlldata === true
                        ? dlData?.prevDesignLibraryId === 31 &&
                          t("landscape_type")
                        : dlData?.isDesignLibraryId === 31 &&
                          t("landscape_type")}
                      {designLibraryData?.isDisplayAlldata === true
                        ? dlData?.prevDesignLibraryId === 30 &&
                          t("interior_type")
                        : dlData?.isDesignLibraryId === 30 &&
                          t("interior_type")}
                      {designLibraryData?.isDisplayAlldata === true
                        ? dlData?.prevDesignLibraryId === 22 &&
                          t("architectural_type")
                        : dlData?.isDesignLibraryId === 22 &&
                          t("architectural_type")}
                      {designLibraryData?.isDisplayAlldata === true
                        ? dlData?.prevDesignLibraryId === 32 &&
                          t("urbanDesign_type")
                        : dlData?.isDesignLibraryId === 32 &&
                          t("urbanDesign_type")}
                    </h4>
                  ) : (
                    <img
                      style={{ width: "3vw" }}
                      src={loader}
                      alt="loader-gif"
                    />
                  )}
                  <ul>
                    {isFirstTierData?.length && !isFirstTierLoading
                      ? isFirstTierData?.length &&
                        isFirstTierData?.map((first, index) => {
                          let isSelected;
                          if (isDisplayFirstTier === true) {
                            isSelected = selectedFirstItemId === first.id;
                          } else {
                            isSelected = null === first.id;
                          }
                          return (
                            <li
                              key={index}
                              className={`${isSelected ? "active" : ""}`}
                              onClick={() => handleTypeClick(first)}
                            >
                              {first?.name?.length > 12 ? (
                                <a /*onClick={() => handleTypeClick(first)}*/>
                                  {lang === "ch" ? first?.alias : first?.name}
                                </a>
                              ) : (
                                <a /*onClick={() => handleTypeClick(first)}*/>
                                  {lang === "ch" ? first?.alias : first?.name}
                                </a>
                              )}
                              {first?.isNew && <span>{"new"}</span>}
                              <div className="icon">
                                <img src={arrow} alt="" />
                              </div>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
                {isDisplayFirstTier === true && (
                  <div
                    className={`tires__main__section__sub-menu ${
                      selectedClassification ? "selected" : ""
                    }`}
                  >
                    {!isSecondTierLoading === true ? (
                      <h4>
                        {designLibraryData?.isDisplayAlldata === true
                          ? dlData?.prevDesignLibraryId === 32 &&
                            t("urbanDesign_classification")
                          : dlData?.isDesignLibraryId === 32 &&
                            t("urbanDesign_classification")}
                        {designLibraryData?.isDisplayAlldata === true
                          ? dlData?.prevDesignLibraryId === 31 &&
                            t("landscape_classification")
                          : dlData?.isDesignLibraryId === 31 &&
                            t("landscape_classification")}
                        {designLibraryData?.isDisplayAlldata === true
                          ? dlData?.prevDesignLibraryId === 30 &&
                            t("interior_classification")
                          : dlData?.isDesignLibraryId === 30 &&
                            t("interior_classification")}
                        {designLibraryData?.isDisplayAlldata === true
                          ? dlData?.prevDesignLibraryId === 22 &&
                            t("architectural_classification")
                          : dlData?.isDesignLibraryId === 22 &&
                            t("architectural_classification")}
                      </h4>
                    ) : (
                      <img
                        style={{ width: "3vw" }}
                        src={loader}
                        alt="loader-gif"
                      />
                    )}
                    <ul>
                      {isSecondTierData?.length && !isSecondTierLoading ? (
                        isSecondTierData?.length &&
                        isSecondTierData?.map((second, index) => {
                          let isSelected;
                          if (isDisplaySecondTier === true) {
                            isSelected = selectedSecondItemId === second.id;
                          } else if (isDisplaySecondTier === false) {
                            isSelected = null === second.id;
                          }
                          return (
                            <li
                              key={index}
                              className={`${isSelected ? "active" : ""}`}
                              onClick={() => handleClassificationClick(second)}
                            >
                              {second?.name?.length > 11 ? (
                                <a /*onClick={() => handleClassificationClick(second)}*/
                                >
                                  {lang === "ch" ? second?.alias : second?.name}
                                </a>
                              ) : (
                                <a /*onClick={() => handleClassificationClick(second)}*/
                                >
                                  {lang === "ch" ? second?.alias : second?.name}
                                </a>
                              )}
                              {second?.isNew && <span>{"new"}</span>}
                              <img src={arrow} alt="" />
                            </li>
                          );
                        })
                      ) : (
                        <>{/* <Loader /> */}</>
                      )}
                    </ul>
                  </div>
                )}
                {isDisplaySecondTier === true && (
                  <div className="tires__main__section__styles">
                    {!isThirdTierLoading ? (
                      <h4>{t("style")}</h4>
                    ) : (
                      <img
                        style={{ width: "3vw" }}
                        src={loader}
                        alt="loader-gif"
                      />
                    )}
                    <div className="tires__main__section__styles__list">
                      {isThirdTierData?.length && !isThirdTierLoading ? (
                        isThirdTierData?.length &&
                        isThirdTierData?.map((third, index) => {
                          return (
                            <div
                              className="tires__main__section__styles__tab"
                              key={index}
                            >
                              <div className="tires__main__section__styles__tab__heading">
                                <h6>
                                  {lang === "ch"
                                    ? third?.style[0]?.tierThreeNameAlias
                                    : third?.style[0]?.tierThreeName}
                                </h6>
                                {third?.style[0]?.isNew && <span>{"new"}</span>}
                              </div>
                              <div className="tires__main__section__styles__tab__images">
                                {third?.style?.map((style, index) => {
                                  const isSelected =
                                    selectedThirdItemId === style.id;
                                  return (
                                    <div
                                      id={style?.id}
                                      className={`tires__main__section__styles__tab__images__image ${
                                        isSelected ? "active" : ""
                                      }`}
                                      key={index}
                                      onClick={() => handleStyleClick(style)}
                                    >
                                      <h5>
                                        {lang === "ch"
                                          ? style?.alias
                                          : style?.name}
                                      </h5>
                                      <img
                                        src={style?.coverImageSuffix}
                                        alt={
                                          lang === "ch"
                                            ? style?.alias
                                            : style?.name
                                        }
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <>{/* <Loader /> */}</>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TiresPopUp;
