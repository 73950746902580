import { CallAwsUrl } from "./apiCalls";

export function processText(inputText, limit, count = 4) {
  let engChars = 0;
  let chnChars = 0;
  const chinesePattern = /[\u4E00-\u9FFF]/;
  const specialCharPattern = /[~!@#$%^&*()_+[\]{};':"\\|<>,.?/]/;

  function isSpecialCharacter(char) {
    return specialCharPattern.test(char);
  }

  for (let i = 0; i < inputText.length; i++) {
    const char = inputText[i];
    if (char.match(chinesePattern)) {
      chnChars += 1;
    } else if (isSpecialCharacter(char)) {
      engChars += 2;
    } else {
      engChars += 1;
    }

    if (engChars + chnChars * count >= limit) {
      inputText = inputText.slice(0, i);
      break;
    }
  }

  const remainingChars = engChars + chnChars * count;
  return { truncatedText: inputText, remainingChars };
}

export function sortAddonsByDate(addons) {
  return addons?.slice()?.sort((a, b) => {
    if (a?.endDate && b?.endDate) {
      return new Date(a?.endDate) - new Date(b?.endDate);
    } else if (a?.endDate) {
      return -1;
    } else if (b?.endDate) {
      return 1;
    } else {
      return 0;
    }
  });
}

export const generateThumbnail = (url, minSize = 256, maxSize = 384) => {
  return new Promise((resolve, reject) => {
    CallAwsUrl((res) => {
      if (!res?.data) {
        reject(new Error("Invalid response from CallAwsUrl"));
        return;
      }
      const img = new Image();
      img.src = `data:image/jpeg;base64,${res?.data}`;

      img.onload = () => {
        const { width, height } = img;

        const aspectRatio = width / height;

        let newWidth, newHeight;

        if (width > height) {
          newWidth = Math.min(Math.max(width, minSize), maxSize);
          newHeight = newWidth / aspectRatio;
        } else {
          newHeight = Math.min(Math.max(height, minSize), maxSize);
          newWidth = newHeight * aspectRatio;
        }

        const canvas = document.createElement("canvas");
        canvas.width = Math.round(newWidth);
        canvas.height = Math.round(newHeight);

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const thumbnailBase64 = canvas.toDataURL("image/jpeg");
        resolve(thumbnailBase64);
      };

      img.onerror = (err) => reject(err);
    }, url);
  });
};

export const ensureBase64Prefix = (base64String) => {
  if (base64String.startsWith("data:image/")) {
    return base64String; // Already has the prefix
  }
  return `data:image/png;base64,${base64String}`;
};