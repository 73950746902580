/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
import React, { useState, useEffect, useContext, useRef } from "react";
import "./WorkSpaceFeedPopUp.scss";
import upscale from "../../../assets/images/workspace/icons/feed-upscale.png";
import base from "../../../assets/images/workspace/icons/feed-base.png";
import download from "../../../assets/images/workspace/icons/feed-download.png";
import favorite from "../../../assets/images/workspace/icons/feed-favorite.png";
import favorite1 from "../../../assets/images/workspace/icons/feed-favorite1.png";
import favorite2 from "../../../assets/images/workspace/icons/feed-favorite2.png";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import next from "../../../assets/images/icons/next-arrow-icon.png";
import prev from "../../../assets/images/icons/pre-arrow-icon.png";
import downarrow from "../../../assets/images/icons/arrow-down.png";
import texti from "../../../assets/images/workspace/icons/textb.png";
import designi from "../../../assets/images/workspace/icons/designb.png";
import imagei from "../../../assets/images/workspace/icons/imageb.png";
import upscalei from "../../../assets/images/workspace/icons/upscaleb.png";
import HDi from "../../../assets/images/workspace/icons/HDb.png";
import enhance from "../../../assets/images/workspace/icons/enhance-a.png";
import extend from "../../../assets/images/workspace/icons/extend-a.png";
import character from "../../../assets/images/workspace/icons/character-a.png";
import eraseri from "../../../assets/images/workspace/icons/eraseri.png";
import inpainti from "../../../assets/images/workspace/icons/inpainti.png";
import shapei from "../../../assets/images/workspace/icons/shapei.png";
import deletedimage from "../../../assets/images/workspace/deleted-image.jpg";
import deleteicon from "../../../assets/images/workspace/icons/feed-delete.png";
import referenceicon from "../../../assets/images/workspace/icons/reference.png";
import relax from "../../../assets/images/workspace/icons/relax.png";
import pro from "../../../assets/images/workspace/icons/pro.png";
import slider1 from "../../../assets/images/icons/slidr-icon.png";
import slider2 from "../../../assets/images/icons/slidr-icon2.png";
import user from "../../../assets/images/icons/user-icon.png";
import applySeed from "../../../assets/images/workspace/icons/apply-seed.png";
import load from "../../../assets/images/workspace/upscale-gif.gif";
import sunny from "../../../assets/images/workspace/atmosphere/sunny.png";
import foggy from "../../../assets/images/workspace/atmosphere/foggy.png";
import rainy from "../../../assets/images/workspace/atmosphere/rainy.png";
import sunset from "../../../assets/images/workspace/atmosphere/sunset.png";
import evening from "../../../assets/images/workspace/atmosphere/evening.png";
import night from "../../../assets/images/workspace/atmosphere/night.png";
import video from "../../../assets/images/workspace/icons/video_a.png";
import {
  CallAwsUrl,
  DeleteImagesGallery,
  GetGenerationSeedData,
  UpdateFavoriteImage,
  formatTeamNames,
} from "../../../utils/apiCalls";
import { ToolsetDataContext } from "../../../store/WorkSpaceToolsetData";
import { UpscaleToolsetContext } from "../../../store/UpscaleToolsetData";
import { EditToolsetContext } from "../../../store/EditToolsetData";
import { resetMask } from "../MaskComponent/ImageMaskPopUp";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
  useReactCompareSliderRef,
} from "react-compare-slider";
import {
  computePostProcessedDimension,
  uploadFile,
  processImage,
  useCreateDefaultMask,
  handleBase64Data,
  handleConvertDateTime,
  extractNameFromUrl,
  formatDate,
  createMaskWithCNImage,
  SCALE_LIMIT,
  ENHANCE_MAX_PIXELS,
} from "../../../utils/workspaceUtils";
import { t } from "i18next";
import Loader from "../../loader";
import InfoTooltipBottom from "../../Tooltips/InfoTooltipBottom";
import TooltipRight from "../../Tooltips/TooltipRight";
import { useParams } from "react-router-dom";
import Confirm from "../../confirmModal/confirm";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import { AtmosphereRefContext } from "../../../store/AtmosphereRefData";
import { RenderToolsetContext } from "../../../store/RenderToolsetData";
import WorkspaceFeedRefPopup from "../WorkspaceFeedRefPopup/WorkspaceFeedRefPopup";
import { Popover } from "antd";
import { UserSocketContext } from "../../../store/UserSocketData";

const CustomSlider = ({
  projectMedias,
  onDownloadClick,
  onCloseClick,
  mediaIndex,
  onUpdateBaseImage,
  onUpdateRefrenceImage,
  onUpscaleBaseImage,
  galleryPopupData,
  isGalleryCurrentIndex,
  handlePopupclose,
  handlePopupShowSlider,
  popupShowSlider,
  popupDynamicValue1,
  popupDynamicValue2,
  showStealth,
  isGalleryImages,
  showAllIcons,
  handleDeletePopup,
  setIsActiveIndex,
  isFavoriteImages,
  setIsFavoriteImages,
  isLoader,
  handleRefPopup,
  setTemplateDisable,
  showDownloadIcon,
  showDeleteIcon,
  handleWorkspacePopupUp,
  handleWorkspacePopupDown,
  generatedAllImages,
  generationIndex,
  setHighlightedMediaId,
  setGalleryPopupData,
  setIsGalleryCurrentIndex,
  isWorkspaceUpAvailable,
  isWorkspaceDownAvailable,
}) => {
  const params = useParams();
  const imageRef = useRef(null);
  const sectionRef = useRef(null);
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const reactCompareSliderRef = useReactCompareSliderRef();
  const { updateToolsetData } = useContext(ToolsetDataContext);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { updateUpscaleToolsetData } = useContext(UpscaleToolsetContext);
  const { updateRenderToolsetData, updateRenderToolsetData1 } =
    useContext(RenderToolsetContext);

  const exampleprojectsJson = JSON.parse(
    localStorage.getItem("exampleProjects")
  );
  const lang = localStorage.getItem("language");
  const isLoginUser = localStorage.getItem("loginUser");
  const loginUserData = JSON.parse(localStorage.getItem("isLoginUser"));
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));

  const [activeIndex, setActiveIndex] = useState(mediaIndex || 0);
  const [showAlternateImage, setShowAlternateImage] = useState(false);
  const [imageLoader, setImageLoader] = useState(true);
  const [showSlider, setShowSlider] = useState(false);
  const [isApplyParameter, setIsApplyParameter] = useState(false);
  const [scale, setScale] = useState(1);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [startTranslate, setStartTranslate] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [cursorStyle, setCursorStyle] = useState("default");
  const [onlyHandleDraggable, setOnlyHandleDraggable] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [open, setOpen] = useState(false);

  const arrayOfIds = exampleprojectsJson?.map((id) => id?.id);
  const isExample = arrayOfIds?.includes(Number(params?.id));
  const isLogin =
    isLoginUser !== "undefined"
      ? JSON.parse(isLoginUser)
      : localStorage.setItem("loginUser", false);

  const sideMenu = [
    {
      id: 1,
      image: sunny,
    },
    {
      id: 2,
      image: foggy,
    },
    {
      id: 3,
      image: rainy,
    },
    {
      id: 4,
      image: sunset,
    },
    {
      id: 5,
      image: evening,
    },
    {
      id: 6,
      image: night,
    },
  ];

  const handleLoad = () => {
    setImageLoader(false);
  };

  const handleActive = (index) => {
    resetImage();
    setImageLoader(true);
    setActiveIndex(index);
    const mediaId = projectMedias[index]?.mediaId;
    setHighlightedMediaId(mediaId);
    setShowAlternateImage(false);
  };

  useEffect(() => {
    const rootContainer = reactCompareSliderRef.current?.rootContainer;

    if (!rootContainer) {
      return;
    }

    const imageTwo = rootContainer.querySelector('img[alt="Image two"]');
    const imageOne = rootContainer.querySelector('img[alt="Image one"]');

    updateImageOneSize(imageOne, imageTwo);
  }, [showSlider, popupShowSlider]);

  const updateImageOneSize = (imageOne, imageTwo) => {
    // if (imageTwoRef.current && imageOneRef.current) {
    // Get the container's dimensions
    const containerWidth = imageTwo.clientWidth;
    const containerHeight = imageTwo.clientHeight;
    // Get the actual image's intrinsic dimensions
    const naturalWidth = imageTwo.naturalWidth;
    const naturalHeight = imageTwo.naturalHeight;
    // Calculate the aspect ratio of the image and container
    const imageAspectRatio = naturalWidth / naturalHeight;
    const containerAspectRatio = containerWidth / containerHeight;

    let renderedWidth, renderedHeight;

    // Determine the rendered dimensions of the image
    if (imageAspectRatio > containerAspectRatio) {
      // Image is wider than the container
      renderedWidth = containerWidth;
      renderedHeight = containerWidth / imageAspectRatio;
    } else {
      // Image is taller than or perfectly fits the container
      renderedWidth = containerHeight * imageAspectRatio;
      renderedHeight = containerHeight;
    }

    // Apply these calculated dimensions to imageOne
    imageOne.style.width = `${renderedWidth}px`;
    imageOne.style.height = `${renderedHeight}px`;
    // }
  };

  const resetImage = () => {
    setScale(1);
    setTranslateX(0); // Reset horizontal translation
    setTranslateY(0); // Reset vertical translation
  };

  const handleWorkspacePopupNext = () => {
    resetImage();
    setImageLoader(true);
    const newIndex = (activeIndex + 1) % projectMedias.length;
    const mediaId = projectMedias[newIndex]?.mediaId;
    setHighlightedMediaId(mediaId);
    setActiveIndex((prevIndex) => (prevIndex + 1) % projectMedias.length);
    setShowAlternateImage(false);
  };

  const handleWorkspacePopupPrev = () => {
    resetImage();
    setImageLoader(true);
    const newIndex =
      (activeIndex - 1 + projectMedias.length) % projectMedias.length;
    const mediaId = projectMedias[newIndex]?.mediaId;
    setHighlightedMediaId(mediaId);
    setActiveIndex(
      (prevIndex) =>
        (prevIndex - 1 + projectMedias.length) % projectMedias.length
    );
    setShowAlternateImage(false);
  };

  const handleGalleryPopupNext = () => {
    if (isGalleryCurrentIndex < isGalleryImages.length - 1) {
      if (isGalleryCurrentIndex !== isGalleryImages.length - 1) {
        resetImage();
        setImageLoader(true);
        const nextIndex = isGalleryCurrentIndex + 1;
        const nextImage = isGalleryImages[nextIndex];
        const createdDate = new Date(nextImage?.created);
        const day = String(createdDate.getDate()).padStart(2, "0");
        const month = String(createdDate.getMonth() + 1).padStart(2, "0");
        const year = createdDate.getFullYear();
        const hours = String(createdDate.getHours()).padStart(2, "0");
        const minutes = String(createdDate.getMinutes()).padStart(2, "0");
        const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

        setGalleryPopupData({
          ...nextImage,
          created: formattedDate,
        });

        setIsGalleryCurrentIndex(nextIndex);
      }
    }
  };

  const handleGalleryPopupPrev = () => {
    if (isGalleryCurrentIndex !== 0) {
      resetImage();
      setImageLoader(true);
      const nextIndex = isGalleryCurrentIndex - 1;
      const nextImage = isGalleryImages[nextIndex];
      const createdDate = new Date(nextImage?.created);
      const day = String(createdDate.getDate()).padStart(2, "0");
      const month = String(createdDate.getMonth() + 1).padStart(2, "0");
      const year = createdDate.getFullYear();
      const hours = String(createdDate.getHours()).padStart(2, "0");
      const minutes = String(createdDate.getMinutes()).padStart(2, "0");
      const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

      setGalleryPopupData({
        ...nextImage,
        created: formattedDate,
      });

      setIsGalleryCurrentIndex(nextIndex);
    }
  };

  const handleWorkspacePopupUp1 = () => {
    resetImage();
    setImageLoader(true);
    const { mediaIndex, mediaId } = handleWorkspacePopupUp(activeIndex);
    setHighlightedMediaId(mediaId);
    setActiveIndex(mediaIndex);
  };

  const handleWorkspacePopupDown1 = () => {
    resetImage();
    setImageLoader(true);
    const { mediaIndex, mediaId } = handleWorkspacePopupDown(activeIndex);
    setHighlightedMediaId(mediaId);
    setActiveIndex(mediaIndex);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && projectMedias?.length > 1) {
        handleWorkspacePopupPrev();
        console.log("workspace key prev");
      } else if (event.key === "ArrowRight" && projectMedias?.length > 1) {
        handleWorkspacePopupNext();
        console.log("workspace key next");
      } else if (event.key === "ArrowUp" && generationIndex > 0) {
        if (isWorkspaceUpAvailable) {
          handleWorkspacePopupUp1();
        }
        console.log("workspace key up");
      } else if (
        event.key === "ArrowDown" &&
        generationIndex < generatedAllImages?.length - 1
      ) {
        if (
          !(
            generationIndex === generatedAllImages?.length - 1 ||
            !isWorkspaceDownAvailable
          )
        ) {
          handleWorkspacePopupDown1();
        }
        console.log("workspace key down");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [generationIndex, generatedAllImages]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" && isGalleryImages?.length > 1) {
        handleGalleryPopupPrev();
        console.log("gallery key prev");
      } else if (event.key === "ArrowRight" && isGalleryImages?.length > 1) {
        handleGalleryPopupNext();
        console.log("gallery key next");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isGalleryImages, isGalleryCurrentIndex]);

  const getImageDisplayedDimensions = () => {
    const image = imageRef.current;
    if (!image) return { width: 0, height: 0, left: 0, top: 0 };

    const naturalWidth = image.naturalWidth;
    const naturalHeight = image.naturalHeight;

    const aspectRatio = naturalWidth / naturalHeight;
    const containerWidth = sectionRef.current.offsetWidth;
    const containerHeight = sectionRef.current.offsetHeight;

    let displayedWidth, displayedHeight, left, top;

    // Calculate displayed dimensions based on object-fit: contain
    if (aspectRatio > containerWidth / containerHeight) {
      // Image is wider relative to container, fit width
      displayedWidth = containerWidth * scale;
      displayedHeight = (naturalHeight / naturalWidth) * displayedWidth;
      left = 0; // Centered horizontally
      top = (containerHeight - displayedHeight) / 2; // Centered vertically
    } else {
      // Image is taller relative to container, fit height
      displayedHeight = containerHeight * scale;
      displayedWidth = (naturalWidth / naturalHeight) * displayedHeight;
      top = 0; // Centered vertically
      left = (containerWidth - displayedWidth) / 2; // Centered horizontally
    }

    return { width: displayedWidth, height: displayedHeight, left, top };
  };

  const handleWheel = (e) => {
    // e.preventDefault();

    // Determine the zoom direction and scale delta and Update the scale
    const delta = e.deltaY < 0 ? 0.1 : -0.1;
    const newScale = Math.min(Math.max(scale + delta, 0.5), 3); // Clamp scale between 0.5 and 3
    setScale(newScale);

    if (newScale <= 1) {
      setTranslateX(0);
      setTranslateY(0);
      return;
    }

    // Get the bounding rectangle of the canvas and that of the container
    const {
      width: displayedWidth,
      height: displayedHeight,
      left,
      top,
    } = getImageDisplayedDimensions();
    const sectionRect = sectionRef.current.getBoundingClientRect();

    // Calculate the center of the image/container
    const containerCenterX = left + displayedWidth / 2;
    const containerCenterY = top + displayedHeight / 2;

    // Calculate the mouse position relative to the center of the image/container
    const mouseX = e.clientX - containerCenterX;
    const mouseY = e.clientY - containerCenterY;

    // Calculate the zoom factor
    const zoomFactor = newScale / scale;
    const newTranslateX =
      translateX / zoomFactor - (mouseX / scale) * (zoomFactor - 1); //this is not working perfectly and it does not make sense but... i works zooming in, but not zooming out
    const newTranslateY =
      translateY / zoomFactor - (mouseY / scale) * (zoomFactor - 1);

    //  and translation state
    const marginX = sectionRect.width * 0.05;
    const marginY = sectionRect.height * 0.05;
    const absMaxTranslateX =
      (sectionRect.width - (sectionRect.width - displayedWidth) / 2 - marginX) /
      scale;
    const absMaxTranslateY =
      (sectionRect.height -
        (sectionRect.height - displayedHeight) / 2 -
        marginY) /
      scale;

    if (mouseX < 0) {
      if (newTranslateX < absMaxTranslateX) {
        setTranslateX(newTranslateX);
      } else {
        setTranslateX(absMaxTranslateX);
      }
    } else if (mouseX > 0) {
      if (newTranslateX > -absMaxTranslateX) {
        setTranslateX(newTranslateX);
      } else {
        setTranslateX(-absMaxTranslateX);
      }
    }

    if (mouseY < 0) {
      if (newTranslateY < absMaxTranslateY) {
        setTranslateY(newTranslateY);
      } else {
        setTranslateY(absMaxTranslateY);
      }
    } else if (mouseY > 0) {
      if (newTranslateY > -absMaxTranslateY) {
        setTranslateY(newTranslateY);
      } else {
        setTranslateY(-absMaxTranslateY);
      }
    }
  };

  // Handle mouse down for dragging
  const handleMouseDown = (e) => {
    if (e.button === 1 || e.button === 0) {
      // 1 is the middle button
      if (scale <= 1) {
        setTranslateX(0);
        setTranslateY(0);
      } else {
        // Handle mouse wheel press
        // console.log("Mouse wheel pressed");
        setCursorStyle("grab");
        e.preventDefault(); // Prevent unwanted selections
        setIsDragging(true);
        // Store the current translate values and mouse position at the start of drag
        setStartTranslate({ x: translateX, y: translateY });
        setMousePosition({ x: e.clientX, y: e.clientY });
      }
    }
  };

  // useEffect(() => {
  //   console.log("onlyHandleDraggable state updated:", onlyHandleDraggable);
  // }, [onlyHandleDraggable]);

  // Handle mouse move for dragging
  const handleMouseMove = (e) => {
    // Handle mouse wheel press
    // console.log("Mouse wheel pressed and moved");
    setCursorStyle("grabbing");
    if (!isDragging) return;
    let deltaX = e.clientX - mousePosition.x;
    let deltaY = e.clientY - mousePosition.y;

    const { width: displayedWidth, height: displayedHeight } =
      getImageDisplayedDimensions();
    const sectionRect = sectionRef.current.getBoundingClientRect();

    const marginX = sectionRect.width * 0.05;
    const marginY = sectionRect.height * 0.05;

    const potentialTranslateX = startTranslate.x + deltaX / scale;
    const potentialTranslateY = startTranslate.y + deltaY / scale;
    const absMaxTranslateX =
      (sectionRect.width - (sectionRect.width - displayedWidth) / 2 - marginX) /
      scale;
    const absMaxTranslateY =
      (sectionRect.height -
        (sectionRect.height - displayedHeight) / 2 -
        marginY) /
      scale;

    if (deltaX > 0) {
      if (potentialTranslateX < absMaxTranslateX) {
        setTranslateX(potentialTranslateX);
      } else {
        setTranslateX(absMaxTranslateX);
      }
    } else if (deltaX < 0) {
      if (potentialTranslateX > -absMaxTranslateX) {
        setTranslateX(potentialTranslateX);
      } else {
        setTranslateX(-absMaxTranslateX);
      }
    }

    if (deltaY > 0) {
      if (potentialTranslateY < absMaxTranslateY) {
        setTranslateY(potentialTranslateY);
      } else {
        setTranslateY(absMaxTranslateY);
      }
    } else if (deltaY < 0) {
      if (potentialTranslateY > -absMaxTranslateY) {
        setTranslateY(potentialTranslateY);
      } else {
        setTranslateY(-absMaxTranslateY);
      }
    }
  };

  // Handle global mouse up
  const handleMouseUp = (e) => {
    if (e.button === 1 || e.button === 0) {
      // 1 is the middle button
      // Handle mouse wheel press
      setCursorStyle("grab");
      console.log("Mouse wheel release");
      setIsDragging(false); // Stop dragging.
      // setOnlyHandleDraggable(false);
      // Set a timeout to reset cursor style to default
      setTimeout(() => {
        setCursorStyle("default"); // Reset to default cursor style
      }, 200); // Adjust the timeout duration (in milliseconds) as needed
    }
  };

  // Set up and clean up global listeners for dragging
  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleDownloadClick = (activeImageIndex) => {
    onDownloadClick(activeImageIndex, true);
  };

  const handleUpdateBaseImage = (activeImageIndex) => {
    onUpdateBaseImage(activeImageIndex, true);
  };

  const handleUpdateRefrenceImage = (activeImageIndex) => {
    onUpdateRefrenceImage(activeImageIndex, 11, true);
  };

  const handleUpscaleClick = (activeImageIndex) => {
    onUpscaleBaseImage(activeImageIndex, true);
    let check = JSON.parse(localStorage.getItem(`enhanceData_${lastPart}`));
    if (check?.extendFidelityLevel) {
      localStorage.setItem(`enhance-option_${lastPart}`, "2");
      check.extendFidelityLevel = 1;
      localStorage.setItem(`enhanceData_${lastPart}`, JSON.stringify(check));
      let data = {
        id: 1,
      };
      localStorage.setItem(`upscaleTypeData_${lastPart}`, JSON.stringify(data));
      const check1 = {
        isTemplate: false,
        isAtmosphere: false,
        isPrompt: false,
        isPrivate: false,
      };
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check1));
      setTemplateDisable(true);
      localStorage.setItem(
        `designLibraryDisable_${lastPart}`,
        JSON.stringify({ isDesign: false })
      );
    }
  };

  const handleFavorite = (index) => {
    // setLoader(true);
    const mediaId =
      galleryPopupData === undefined
        ? projectMedias[index]?.mediaId
        : galleryPopupData?.id;
    const updatedIsFavoriteImages = { ...isFavoriteImages };
    const currentValue = updatedIsFavoriteImages[mediaId];
    updatedIsFavoriteImages[mediaId] = !currentValue;
    setIsFavoriteImages(updatedIsFavoriteImages);
    const payload = {
      mediaId: mediaId,
    };
    UpdateFavoriteImage((res) => {
      if (res?.status === 200) {
        const updatedFavoriteValue = res?.data?.isFavorite;
        // setIsFavoriteImages((prevState) => ({
        //   ...prevState,
        //   [res?.data?.mediaId]: updatedFavoriteValue,
        // }));
        // setLoader(false);
      } else {
        // setLoader(false);
        const updatedIsFavoriteImages = { ...isFavoriteImages };
        const currentValue = updatedIsFavoriteImages[mediaId];
        updatedIsFavoriteImages[mediaId] = !currentValue;
        setIsFavoriteImages(updatedIsFavoriteImages);
      }
    }, payload);
  };

  const handleCrossClick = () => {
    onCloseClick();
  };

  const [originalImage1, setOriginalImage1] = useState(new Image());
  const [originalImage2, setOriginalImage2] = useState(new Image());
  const [originalImage3, setOriginalImage3] = useState(new Image());
  const [originalImage4, setOriginalImage4] = useState(new Image());
  const [alternateImage, setAlternateImage] = useState(new Image());

  useEffect(() => {
    if (projectMedias?.length > 1) {
      originalImage1.src = projectMedias[0]?.url;
      originalImage2.src = projectMedias[1]?.url;
      originalImage3.src = projectMedias[2]?.url;
      originalImage4.src = projectMedias[3]?.url;
    } else {
      originalImage1.src = projectMedias[activeIndex]?.url;
    }

    if (projectMedias[0]?.baseImage !== null) {
      alternateImage.src = projectMedias[0]?.baseImage;
    }
  }, [projectMedias[activeIndex]]);

  const dynamicValue1 = projectMedias[activeIndex]?.toolset
    ?.split(",")[0]
    ?.toUpperCase();

  const dynamicValue2 = projectMedias[activeIndex]?.toolset
    ?.split(",")[1]
    ?.toUpperCase();

  useEffect(() => {
    setShowSlider(false); // this is causing one frame delays, showing delete image/src sometimes. next work on this
  }, [projectMedias[activeIndex], galleryPopupData]);

  const handleShowSlider = () => {
    setShowSlider(true);
  };

  const ApplySeedData = () => {
    setIsApplyParameter(true);
    const url = window.location.pathname;
    const projectId = url.substring(url.lastIndexOf("/") + 1);
    const mediaId =
      galleryPopupData === undefined
        ? projectMedias[activeIndex]?.mediaId
        : galleryPopupData?.id;
    const toolset =
      galleryPopupData === undefined
        ? projectMedias[activeIndex]?.toolset
        : galleryPopupData?.toolset;
    const isUpscale = projectMedias[0]?.toolset?.includes("upscale");

    let dlData = JSON.parse(localStorage.getItem(`dlData_${projectId}`));
    let tiresData = JSON.parse(
      localStorage.getItem(`designLibraryData_${projectId}`)
    );
    let toolsetLocal = JSON.parse(
      localStorage.getItem(`toolsetData_${projectId}`)
    );
    let atmosphere = JSON.parse(
      localStorage.getItem(`atmosphereData_${projectId}`)
    );
    let cameraData = JSON.parse(
      localStorage.getItem(`cameraData_${projectId}`)
    );
    let imageTypeData = JSON.parse(
      localStorage.getItem(`imageTypeData_${projectId}`)
    );
    let imageCountData = JSON.parse(
      localStorage.getItem(`imageCount_${projectId}`)
    );
    let promptLocalData = JSON.parse(
      localStorage.getItem(`promptData_${projectId}`)
    );
    let imageFidelityLocal = JSON.parse(
      localStorage.getItem(`image-fidelity_${projectId}`)
    );
    let selection = localStorage.getItem(`selection_${projectId}`);
    let privateModel = "";
    if (promptLocalData?.privateModel?.value === 1) {
      privateModel = "";
    } else {
      privateModel = `${promptLocalData?.privateModel?.value};${promptLocalData?.privateModel?.label};${promptLocalData?.privateModel?.modelNameAlias};${promptLocalData?.privateModelValue};${promptLocalData?.privateModel?.modelPrompt}`;
    }
    let atmosphereRefLocal = JSON.parse(
      localStorage.getItem(`atmosphereRefLocal_${projectId}`)
    );
    let templateData = JSON.parse(
      localStorage.getItem(`isTemplate_${projectId}`)
    );
    let graphicData = JSON.parse(
      localStorage.getItem(`graphicStyleData_${projectId}`)
    );
    let enhanceLocal = JSON.parse(
      localStorage.getItem(`enhanceData_${lastPart}`)
    );
    let imageResolutionLocal = JSON.parse(
      localStorage.getItem(`image-resolution_${lastPart}`)
    );

    GetGenerationSeedData((res) => {
      if (res?.statusCode === 200) {
        const data = res?.data;
        //toolset
        if (toolset === "text") {
          const newToolsetData = {
            toolsetActive: 0,
          };
          localStorage.setItem(
            `activeToolset_${lastPart}`,
            JSON.stringify(newToolsetData)
          );
          updateToolsetData(newToolsetData);
          if (dlData) {
            const ratio =
              data?.objThree?.imageRatio === "4:3"
                ? 0
                : data?.objThree?.imageRatio === "16:9"
                ? 3
                : data?.objThree?.imageRatio === "3:2"
                ? 1
                : 2;
            toolsetLocal.imageOrientationName =
              data?.objThree?.imageOrientationName;
            toolsetLocal.orientation = Number(data?.objThree?.orientation);
            toolsetLocal.imageRatio = ratio;
            toolsetLocal.imageRatioName = data?.objThree?.imageRatioName;
          }
        } else if (toolset === "design") {
          const newToolsetData = {
            toolsetActive: 1,
          };
          localStorage.setItem(
            `activeToolset_${lastPart}`,
            JSON.stringify(newToolsetData)
          );
          updateToolsetData(newToolsetData);
          toolsetLocal.renderFidelityLevel =
            data?.objThree?.renderFidelityLevel;
          if (data?.objThree?.preserveMaterial !== 0) {
            toolsetLocal.preserveMaterial = data?.objThree?.preserveMaterial;
            const data1 = {
              valid: true,
            };
            localStorage.setItem(`preserve_${lastPart}`, JSON.stringify(data1));
          } else {
            const data1 = {
              valid: false,
            };
            localStorage.setItem(`preserve_${lastPart}`, JSON.stringify(data1));
          }
        } else if (toolset === "image") {
          const newToolsetData = {
            toolsetActive: 2,
          };
          localStorage.setItem(
            `activeToolset_${lastPart}`,
            JSON.stringify(newToolsetData)
          );
          updateToolsetData(newToolsetData);
          //toolsetLocal.editFidelityLevel = data?.objThree?.editFidelityLevel;
          //toolsetLocal.maskFidelityLevel = data?.objThree?.preserveMaterial;
          if (data?.objThree?.preserveMaterial != null) {
            toolsetLocal.maskFidelityLevel = data?.objThree?.preserveMaterial;
          }
          if (data?.objThree?.editFidelityLevel != null) {
            if (data?.objThree?.editFidelityLevel === 0) {
              imageFidelityLocal.valid = false;
            } else if (data?.objThree?.editFidelityLevel !== 1) {
              imageFidelityLocal.valid = true;
            }
            imageFidelityLocal.id = data?.objThree?.editFidelityLevel;
          }
          //selection, if its control inpaint we jump to selection as 2
          if (projectMedias[activeIndex]?.controlLevel) {
            localStorage.setItem(`selection_${projectId}`, 2);
          } else {
            localStorage.setItem(`selection_${projectId}`, 1);
          }
        } else {
          const newToolsetData = {
            toolsetActive: 3,
          };
          localStorage.setItem(
            `activeToolset_${lastPart}`,
            JSON.stringify(newToolsetData)
          );
          updateToolsetData(newToolsetData);
        }
        if (
          toolset?.split(",")[0] !== "upscale" ||
          (toolset?.split(",")[0] === "upscale" &&
            toolset?.split(",")[1] === "outpainting")
        ) {
          setTemplateDisable(false);
          //design library
          if (data?.objTwo?.isFirstTierId === 9999) {
            templateData.isTemplate = false;
            localStorage.setItem(
              `isTemplate_${projectId}`,
              JSON.stringify(templateData)
            );
          } else {
            templateData.isTemplate = true;
            localStorage.setItem(
              `isTemplate_${projectId}`,
              JSON.stringify(templateData)
            );
            localStorage.setItem(
              `dlData_${projectId}`,
              JSON.stringify(data?.ObjOne)
            );
            localStorage.setItem(
              `designLibraryData_${projectId}`,
              JSON.stringify(data?.objTwo)
            );
            const updateAtmosphere = {
              ...data?.objFour,
              seasonIcon:
                data?.objFour?.seasonName === "sunny"
                  ? sideMenu[0]?.image
                  : data?.objFour?.seasonName === "foggy"
                  ? sideMenu[1]?.image
                  : data?.objFour?.seasonName === "rainy"
                  ? sideMenu[2]?.image
                  : data?.objFour?.seasonName === "sunset"
                  ? sideMenu[3]?.image
                  : data?.objFour?.seasonName === "evening"
                  ? sideMenu[4]?.image
                  : sideMenu[5]?.image,
            };
            localStorage.setItem(
              `atmosphereData_${projectId}`,
              JSON.stringify(updateAtmosphere)
            );
            localStorage.setItem(
              `cameraData_${projectId}`,
              JSON.stringify(data?.objFive)
            );
            localStorage.setItem(
              `graphicStyleData_${projectId}`,
              JSON.stringify(data?.objSeven)
            );
          }
          //reference image
          if (data?.objEight?.referenceImage?.length !== 0) {
            const ref = {
              feedDesignBase64: data?.objEight?.referenceImage[0]?.url,
              uploadedBaseImageUrl: data?.objEight?.referenceImage[0]?.url,
              refFidelityLevel: data?.objEight?.referenceImage[0]?.weight,
            };
            atmosphereRefLocal[0] = ref;
            templateData.isAtmosphere = true;
            localStorage.setItem(
              `isTemplate_${projectId}`,
              JSON.stringify(templateData)
            );
          } else {
            templateData.isAtmosphere = false;
            localStorage.setItem(
              `isTemplate_${projectId}`,
              JSON.stringify(templateData)
            );
          }
          //additional prompt
          if (
            (data?.objSix?.prompt !== "" && data?.objSix?.prompt !== null) ||
            (data?.objSix?.negativePrompt !== "" &&
              data?.objSix?.negativePrompt !== null)
          ) {
            templateData.isPrompt = true;
            localStorage.setItem(
              `isTemplate_${projectId}`,
              JSON.stringify(templateData)
            );
            localStorage.setItem(
              `promptData_${projectId}`,
              JSON.stringify(data?.objSix)
            );
            localStorage.setItem(
              `smartPrompt_${projectId}`,
              JSON.stringify({ text: data?.objSix?.prompt })
            );
          } else {
            templateData.isPrompt = false;
            localStorage.setItem(
              `isTemplate_${projectId}`,
              JSON.stringify(templateData)
            );
            localStorage.setItem(
              `promptData_${projectId}`,
              JSON.stringify(data?.objSix)
            );
          }
          //privateModel
          if (!data?.objSix?.privateModelValue) {
            templateData.isPrivate = false;
            localStorage.setItem(
              `isTemplate_${projectId}`,
              JSON.stringify(templateData)
            );
          } else {
            templateData.isPrivate = true;
            localStorage.setItem(
              `isTemplate_${projectId}`,
              JSON.stringify(templateData)
            );
          }
          //toolset
          localStorage.setItem(
            `toolsetData_${projectId}`,
            JSON.stringify(toolsetLocal)
          );
          // imageFidelity
          localStorage.setItem(
            `image-fidelity_${projectId}`,
            JSON.stringify(imageFidelityLocal)
          );
          //select
          if (toolset === "image" || toolset === "design") {
            localStorage.setItem(
              `imageTypeData_${projectId}`,
              JSON.stringify(data?.objFivePointFive)
            );
            if (toolset === "image" && data?.objEight?.initialCNImage) {
              localStorage.setItem(`selection_${lastPart}`, 2);
            }
          }
          //atmosphere
          localStorage.setItem(
            `atmosphereRefLocal_${projectId}`,
            JSON.stringify(atmosphereRefLocal)
          );
          // imageResolution
          if (toolset === "design") {
            imageResolutionLocal.valid = true;
            imageResolutionLocal.id =
              projectMedias[activeIndex]?.megapixels === 4 ? 2 : 1;
            localStorage.setItem(
              `image-resolution_${lastPart}`,
              JSON.stringify(imageResolutionLocal)
            );
          }
          //imageCount
          localStorage.setItem(
            `imageCount_${projectId}`,
            JSON.stringify(data?.objEight?.imageCount)
          );
          //original image
          if (toolset === "design") {
            const renderUpdate = {
              feedDesignBase64: data?.objEight?.baseImage,
              uploadedBaseImageUrl: data?.objEight?.baseImage?.path,
            };
            localStorage.setItem(
              `designBaseimg_${lastPart}`,
              JSON.stringify(renderUpdate)
            );
            updateRenderToolsetData({
              feedDesignBase64: data?.objEight?.baseImage,
              uploadedBaseImageUrl: data?.objEight?.baseImage?.path,
            });
            onCloseClick();
            // onUpdateRefrenceImage(activeIndex, 1, true);
          } else if (toolset === "image") {
            onUpdateRefrenceImage(activeIndex, 2, true, true);
            onCloseClick();
          }
        }
        // Upscale
        if (isUpscale) {
          // onUpdateRefrenceImage(activeIndex, 3, true);
          updateUpscaleToolsetData({
            feedUpBase64: data?.objEight?.baseImage,
            isUpScaleURLImgUrl: data?.objEight?.baseImage?.path,
          });
          const upscaleUpdate = {
            feedUpBase64: data?.objEight?.baseImage,
            isUpScaleURLImgUrl: data?.objEight?.baseImage?.path,
          };
          localStorage.setItem(
            `upscaleBaseimg_${lastPart}`,
            JSON.stringify(upscaleUpdate)
          );
          if (toolset?.split(",")[1] === "outpainting") {
            if (enhanceLocal?.extendFidelityLevel) {
              localStorage.setItem(`enhance-option_${lastPart}`, "1");
              if (projectMedias[activeIndex]?.verticalPercentage !== 0) {
                if (projectMedias[activeIndex]?.verticalPercentage === 40) {
                  enhanceLocal.extendFidelityLevel = -1;
                } else if (
                  projectMedias[activeIndex]?.verticalPercentage === 60
                ) {
                  enhanceLocal.extendFidelityLevel = -2;
                } else if (
                  projectMedias[activeIndex]?.verticalPercentage === 80
                ) {
                  enhanceLocal.extendFidelityLevel = -3;
                } else if (
                  projectMedias[activeIndex]?.verticalPercentage === 100
                ) {
                  enhanceLocal.extendFidelityLevel = -4;
                }
              } else if (
                projectMedias[activeIndex]?.horizontalPercentage !== 0
              ) {
                if (projectMedias[activeIndex]?.horizontalPercentage === 40) {
                  enhanceLocal.extendFidelityLevel = 1;
                } else if (
                  projectMedias[activeIndex]?.horizontalPercentage === 60
                ) {
                  enhanceLocal.extendFidelityLevel = 2;
                } else if (
                  projectMedias[activeIndex]?.horizontalPercentage === 80
                ) {
                  enhanceLocal.extendFidelityLevel = 3;
                } else if (
                  projectMedias[activeIndex]?.horizontalPercentage === 100
                ) {
                  enhanceLocal.extendFidelityLevel = 4;
                }
              }
              setTemplateDisable(false);
            }
          } else if (toolset?.split(",")[1] === "people") {
            localStorage.setItem(`enhance-option_${lastPart}`, "3");
            setTemplateDisable(true);
          } else if (toolset?.split(",")[1] === "creative") {
            localStorage.setItem(`enhance-option_${lastPart}`, "2");
            setTemplateDisable(true);
            let upscaleNewType;
            if(feedData?.prompts === "Creative boost" || feedData?.prompts === "创意提升") {
              upscaleNewType = {
                id: 3,
              };
            } else {
            upscaleNewType = {
              id: 2,
            };
            }
            localStorage.setItem(
              `upscaleTypeData_${lastPart}`,
              JSON.stringify(upscaleNewType)
            );
          } else {
            localStorage.setItem(`enhance-option_${lastPart}`, "2");
            setTemplateDisable(true);
            const upscaleNewType = {
              id: 1,
            };
            localStorage.setItem(
              `upscaleTypeData_${lastPart}`,
              JSON.stringify(upscaleNewType)
            );
          }
          localStorage.setItem(
            `enhanceData_${lastPart}`,
            JSON.stringify(enhanceLocal)
          );
          if (
            toolset?.split(",")[1] &&
            toolset?.split(",")[1] !== "outpainting"
          ) {
            localStorage.setItem(`imageCount_${projectId}`, JSON.stringify(1));
          }
        }
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
      setIsApplyParameter(false);
      onCloseClick();
    }, mediaId);
  };

  const isUpscale = projectMedias[0]?.toolset?.includes("upscale");
  let isUpscaleFeed;
  if (projectMedias[0]?.toolset?.split(",")[1]) {
    isUpscaleFeed = projectMedias[0]?.toolset
      ?.split(",")[1]
      ?.includes("upscale");
  }
  let isSecondValueNoStyle = false;
  let secondValue = "";

  if (galleryPopupData !== undefined && galleryPopupData?.prompts) {
    const splitPrompts = galleryPopupData?.prompts.split(",");
    const filteredPrompts = splitPrompts.filter(
      (item) => item.trim() !== "" && item !== "null" && item !== null
    );
    if (filteredPrompts.length >= 2) {
      secondValue = splitPrompts[1].trim();
      isSecondValueNoStyle =
        secondValue === (lang !== "ch" ? "No Style" : "无风格");
    }
  } else {
    if (projectMedias[activeIndex]?.prompts) {
      const splitPrompts = projectMedias[activeIndex]?.prompts.split(",");
      const filteredPrompts = splitPrompts.filter(
        (item) => item.trim() !== "" && item !== "null" && item !== null
      );
      if (filteredPrompts.length >= 2) {
        secondValue = splitPrompts[1].trim();
        isSecondValueNoStyle =
          secondValue === (lang !== "ch" ? "No Style" : "无风格");
      }
    }
  }

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  let isFavorite = false;
  if (galleryPopupData !== undefined && showAllIcons) {
    const active = galleryPopupData?.id;
    isFavorite = isFavoriteImages[active];
  } else if (galleryPopupData === undefined) {
    const active = projectMedias[activeIndex]?.mediaId;
    isFavorite = isFavoriteImages[active];
  }

  const feedData =
    galleryPopupData === undefined
      ? projectMedias[activeIndex]
      : galleryPopupData;
  return (
    <div className="custom-slider">
      <div className="feed__popup__section__heading">
        <div className="feed__popup__section__heading__container">
          {galleryPopupData === undefined ? (
            <div className="feed__popup__section__heading__icons">
              <div
                className="upscale-icon tooltip-top image-icon"
                style={{ position: "relative" }}
              >
                {loginUserData?.id !== projectMedias?.[0]?.userId ? (
                  <>
                    <img
                      src={favorite}
                      alt="favorite-iocn"
                      style={{ cursor: "not-allowed" }}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={
                            t("favorite") + t("action_disallowed_other_team")
                          }
                          display={{
                            width: `${lang === "ch" ? "200px" : "208px"}`,
                            left: `${lang === "ch" ? "-186px" : "-214px"}`,
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={isFavorite ? favorite1 : favorite}
                      alt="favorite-iocn"
                      className="fav"
                      onClick={() => handleFavorite(activeIndex)}
                    />
                    <img
                      src={favorite2}
                      alt="favorite-iocn"
                      className="fav1"
                      onClick={() => handleFavorite(activeIndex)}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("favorite")}
                          display={{
                            width: `${lang === "ch" ? "80px" : "112px"}`,
                            left: `${lang === "ch" ? "-86px" : "-112px"}`,
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
              {feedData?.toolset?.split(",")[0] !== "video" && (
                <>
                  {feedData?.toolset?.split(",")[0] !== "eraser" &&
                  isUpscaleFeed !== true ? (
                    <div
                      className="upscale-icon tooltip-top image-icon"
                      style={{ position: "relative" }}
                    >
                      <img
                        className={` ${isApplyParameter ? "active-load" : ""}`}
                        src={applySeed}
                        alt="seed-iocn"
                        onClick={() => ApplySeedData()}
                      />
                      <div className="tooltip-show">
                        {!responsiveData && (
                          <TooltipRight
                            TooltipContent={t("apply_seed")}
                            display={{
                              width: `${lang === "ch" ? "136px" : "148px"}`,
                              left: `${lang === "ch" ? "-136px" : "-148px"}`,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="upscale-icon tooltip-top image-icon"
                      style={{ position: "relative" }}
                    >
                      <img
                        className="active-load"
                        src={applySeed}
                        alt="seed-iocn"
                        onClick={() => ApplySeedData()}
                      />
                      <div className="tooltip-show">
                        {!responsiveData && (
                          <TooltipRight
                            TooltipContent={t(
                              `${feedData?.toolset?.split(",")[0]}_apply_seed`
                            )}
                            display={{
                              width: `${lang === "ch" ? "182px" : "228px"}`,
                              left: `${lang === "ch" ? "-182px" : "-228px"}`,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className="upscale-icon tooltip-top image-icon"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={base}
                      alt="base-iocn"
                      onClick={() => handleUpdateBaseImage(activeIndex)}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("send_to_image_toolset")}
                          display={{
                            width: `${lang === "ch" ? "150px" : "168px"}`,
                            left: `${lang === "ch" ? "-148px" : "-174px"}`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="upscale-icon tooltip-top image-icon"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={referenceicon}
                      alt="reference-iocn"
                      onClick={() => handleUpdateRefrenceImage(activeIndex)}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("send_to_reference_image")}
                          display={{
                            width: `${lang === "ch" ? "136px" : "198px"}`,
                            left: `${lang === "ch" ? "-136px" : "-198px"}`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="upscale-icon tooltip-top"
                    style={{ position: "relative" }}
                  >
                    {
                      /*isUpscale ? (
                  <>
                    <img
                      src={upscalei}
                      alt="enhance-iocn"
                      className="disabled small-icon"
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("already_upscaled")}
                          display={{
                            width: `${lang === "ch" ? "156px" : "240px"}`,
                            left: `${lang === "ch" ? "-162px" : "-246px"}`,
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : (*/
                      <>
                        <img
                          className={` ${
                            isLoader ? "active-load" : ""
                          } small-icon`}
                          src={HDi}
                          alt="enhance-iocn"
                          onClick={() => handleUpscaleClick(activeIndex)}
                        />
                        <div className="tooltip-show">
                          {!responsiveData && (
                            <TooltipRight
                              TooltipContent={t("sent_to_standard")}
                              display={{
                                width: `${lang === "ch" ? "160px" : "160px"}`,
                                left: `${lang === "ch" ? "-160px" : "-160px"}`,
                              }}
                            />
                          )}
                        </div>
                      </>
                      //)
                    }
                  </div>
                </>
              )}
              <div
                className="download-icon tooltip-top"
                style={{ position: "relative" }}
              >
                <img
                  src={download}
                  alt="download-iocn"
                  onClick={() => handleDownloadClick(activeIndex)}
                />
                <div className="tooltip-show">
                  {!responsiveData && (
                    <TooltipRight
                      TooltipContent={t("download")}
                      display={{
                        width: `${lang === "ch" ? "66px" : "102px"}`,
                        left: `${lang === "ch" ? "-66px" : "-108px"}`,
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className="delete-icon tooltip-top"
                style={{ position: "relative" }}
              >
                {loginUserData?.id !== projectMedias?.[0]?.userId ? (
                  <>
                    <img
                      src={deleteicon}
                      alt="download-iocn"
                      style={{ cursor: "not-allowed" }}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={
                            t("delete") + t("action_disallowed_other_team")
                          }
                          display={{
                            width: `${lang === "ch" ? "200px" : "202px"}`,
                            left: `${lang === "ch" ? "-166px" : "-208px"}`,
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={deleteicon}
                      alt="download-iocn"
                      onClick={() => {
                        handleDeletePopup();
                        setIsActiveIndex(activeIndex);
                      }}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("delete")}
                          display={{
                            width: `${lang === "ch" ? "66px" : "102px"}`,
                            left: `${lang === "ch" ? "-66px" : "-108px"}`,
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              {showAllIcons === true ? (
                <div className="feed__popup__section__heading__icons">
                  <div
                    className="download-icon tooltip-top"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={isFavorite ? favorite1 : favorite}
                      alt="favorite-iocn"
                      className="fav"
                      onClick={() => handleFavorite(galleryPopupData)}
                    />
                    <img
                      src={favorite2}
                      className="fav1"
                      alt="favorite-iocn"
                      onClick={() => handleFavorite(galleryPopupData)}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("favorite")}
                          display={{
                            width: `${lang === "ch" ? "60px" : "102px"}`,
                            left: `${lang === "ch" ? "-66px" : "-108px"}`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="download-icon tooltip-top"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={download}
                      alt="download-iocn"
                      onClick={() => handleDownloadClick(activeIndex)}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("download")}
                          display={{
                            width: `${lang === "ch" ? "60px" : "102px"}`,
                            left: `${lang === "ch" ? "-66px" : "-108px"}`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="delete-icon tooltip-top"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={deleteicon}
                      alt="download-iocn"
                      onClick={handleDeletePopup}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("delete")}
                          display={{
                            width: `${lang === "ch" ? "60px" : "102px"}`,
                            left: `${lang === "ch" ? "-66px" : "-108px"}`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {showDownloadIcon && (
                    <div className="feed__popup__section__heading__icons">
                      <div
                        className="download-icon tooltip-top"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={download}
                          alt="download-iocn"
                          onClick={() => handleDownloadClick(activeIndex)}
                        />
                        <div className="tooltip-show">
                          {!responsiveData && (
                            <TooltipRight
                              TooltipContent={t("download")}
                              display={{
                                width: `${lang === "ch" ? "60px" : "102px"}`,
                                left: `${lang === "ch" ? "-66px" : "-108px"}`,
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {showDeleteIcon && (
                        <div
                          className="delete-icon tooltip-top"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={deleteicon}
                            alt="download-iocn"
                            onClick={handleDeletePopup}
                          />
                          <div className="tooltip-show">
                            {!responsiveData && (
                              <TooltipRight
                                TooltipContent={t("delete")}
                                display={{
                                  width: `${lang === "ch" ? "60px" : "102px"}`,
                                  left: `${lang === "ch" ? "-66px" : "-108px"}`,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <div
            className="feed__popup__section__heading__close"
            onClick={
              galleryPopupData !== undefined
                ? handlePopupclose
                : handleCrossClick
            }
          >
            <img
              src={cross}
              alt="cross-iocn"
              onClick={
                galleryPopupData !== undefined
                  ? handlePopupclose
                  : handleCrossClick
              }
            />
          </div>
        </div>
      </div>
      <div className="custom-slider__sections">
        <div className="custom-slider__main">
          <div
            className="custom-slider__container"
            onWheel={handleWheel}
            ref={sectionRef}
          >
            {galleryPopupData !== undefined ? (
              <>
                {isGalleryCurrentIndex === 0 ? (
                  <div
                    className="prev-div"
                    style={{ cursor: "default", opacity: "0.7" }}
                  >
                    <img
                      className="prev"
                      src={prev}
                      alt="prev-icon"
                      style={{ cursor: "default" }}
                    />
                  </div>
                ) : (
                  <div className="prev-div" onClick={handleGalleryPopupPrev}>
                    <img className="prev" src={prev} alt="prev-icon" />
                  </div>
                )}
              </>
            ) : (
              <>
                {projectMedias?.length !== 1 && (
                  <div className="prev-div" onClick={handleWorkspacePopupPrev}>
                    <img className="prev" src={prev} alt="prev-icon" />
                  </div>
                )}
              </>
            )}
            <>
              {feedData?.toolset === "video" ? (
                <div className="slider-image">
                  <video
                    key={feedData?.url}
                    controls
                    // autoPlay
                    // loop
                    // muted
                    playsInline
                    className=""
                    style={{
                      objectFit: "contain",
                      maxHeight: "100%",
                      maxWidth: "100%",
                      minHeight: "70%",
                      minWidth: "80%",
                    }}
                  >
                    <source src={feedData?.url} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <div className="slider-image">
                  <img
                    data-draggable="true"
                    onLoad={handleLoad}
                    onError={handleLoad}
                    src={
                      galleryPopupData !== undefined
                        ? feedData?.url
                        : feedData?.isActive === true
                        ? feedData?.baseImage !== null
                          ? feedData?.url
                          : feedData?.url
                        : deletedimage
                    }
                    style={{
                      cursor: cursorStyle,
                      transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
                      transformOrigin: "center",
                      transition: isDragging
                        ? "none"
                        : "transform 0.2s ease-out",
                      display: showSlider ? "none" : "block",
                    }}
                    alt={`Image ${activeIndex}`}
                    onMouseDown={handleMouseDown}
                    ref={imageRef}
                  />
                  {galleryPopupData !== undefined ? (
                    <>
                      {feedData?.baseImage !== null && showSlider === false && (
                        <div className="slider-image__icons">
                          <img
                            src={slider2}
                            className="one"
                            alt="slider-icon"
                          />
                          <img
                            src={slider1}
                            className="two"
                            alt="slider-icon"
                            onClick={handleShowSlider}
                          />
                          <div className="tooltip-show">
                            {!responsiveData && (
                              <InfoTooltipBottom
                                TooltipContent={t("view_original")}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {feedData?.baseImage !== null &&
                        feedData?.isActive === true &&
                        showSlider === false && (
                          <div className="slider-image__icons">
                            <img
                              src={slider2}
                              className="one"
                              alt="slider-icon"
                            />
                            <img
                              src={slider1}
                              className="two"
                              alt="slider-icon"
                              onClick={handleShowSlider}
                            />
                            <div className="tooltip-show">
                              {!responsiveData && (
                                <InfoTooltipBottom
                                  TooltipContent={t("view_original")}
                                />
                              )}
                            </div>
                          </div>
                        )}
                    </>
                  )}
                  {feedData?.baseImage !== null && (
                    <ReactCompareSlider // there is still a little bit of transition issue, but much better, the issue is the process of loading, add loading to it if necessary
                      ref={reactCompareSliderRef}
                      onlyHandleDraggable={onlyHandleDraggable} // a bit problamatic by daily
                      style={{
                        cursor: cursorStyle,
                        position: "absolute",
                        display: showSlider ? "block" : "none",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
                        transformOrigin: "center",
                        transition: isDragging
                          ? "none"
                          : "transform 0.2s ease-out",
                      }}
                      itemOne={
                        <ReactCompareSliderImage
                          src={feedData?.baseImage}
                          srcSet={feedData?.baseImage}
                          alt="Image one"
                          style={{
                            objectFit: "contain",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      }
                      itemTwo={
                        <ReactCompareSliderImage
                          src={feedData?.url}
                          srcSet={feedData?.url}
                          alt="Image two"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                            position: "relative",
                          }}
                        />
                      }
                    ></ReactCompareSlider>
                  )}
                  {imageLoader === true && (
                    <div
                      className="gallery__popup__left__loader"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </div>
              )}
            </>
            {galleryPopupData !== undefined ? (
              <>
                {isGalleryCurrentIndex === isGalleryImages?.length - 1 ? (
                  <div
                    className="next-div"
                    style={{ cursor: "default", opacity: "0.7" }}
                  >
                    <img
                      className="next"
                      src={next}
                      alt="next-icon"
                      style={{ cursor: "default" }}
                    />
                  </div>
                ) : (
                  <div className="next-div" onClick={handleGalleryPopupNext}>
                    <img className="next" src={next} alt="next-icon" />
                  </div>
                )}
              </>
            ) : (
              <>
                {projectMedias?.length !== 1 && (
                  <div className="next-div" onClick={handleWorkspacePopupNext}>
                    <img className="next" src={next} alt="next-icon" />
                  </div>
                )}
              </>
            )}
          </div>
          {galleryPopupData === undefined ? (
            // <div className="underline">
            //   {projectMedias?.map((_, index) => (
            //     <div
            //       key={index}
            //       className={`underline-item ${
            //         index === activeIndex ? "active" : ""
            //       } ${projectMedias.length < 2 ? "single" : ""}`}
            //     />
            //   ))}
            // </div>
            <div className="bottom-sec">
              <div className="carousel">
                <div className="carousel__container">
                  {projectMedias?.map((card, imageindex) => {
                    const active = activeIndex === imageindex;
                    return (
                      <div
                        className={`carousel__card ${active ? "active" : ""}`}
                        key={imageindex}
                        onClick={active ? null : () => handleActive(imageindex)}
                      >
                        <img src={card?.thumbnail} alt="carousel-img" />
                        <div className="shadow"></div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="gen-change">
                <Popover
                  overlayClassName={`feed-popover ${lang === "ch" ? "ch" : ""}`}
                  content={t("feed_popup")}
                  arrow={true}
                  trigger="hover"
                  placement="bottom"
                  open={open}
                  onOpenChange={handleOpen}
                >
                  <div
                    className={`gen-change__up-arrow ${
                      generationIndex === 0 || !isWorkspaceUpAvailable
                        ? "disabled"
                        : ""
                    }`}
                    onClick={
                      generationIndex === 0 || !isWorkspaceUpAvailable
                        ? null
                        : () => handleWorkspacePopupUp1()
                    }
                  >
                    <img className="active" src={next} alt="up-arrow" />
                    <img className="default" src={downarrow} alt="up-arrow" />
                  </div>
                </Popover>
                <div
                  className={`gen-change__down-arrow ${
                    generationIndex === generatedAllImages?.length - 1 ||
                    !isWorkspaceDownAvailable
                      ? "disabled"
                      : ""
                  }`}
                  onClick={
                    generationIndex === generatedAllImages?.length - 1 ||
                    !isWorkspaceDownAvailable
                      ? null
                      : () => handleWorkspacePopupDown1()
                  }
                >
                  <img className="active" src={prev} alt="down-arrow" />
                  <img className="default" src={downarrow} alt="down-arrow" />
                </div>
              </div>
            </div>
          ) : (
            <div className="underline">
              {[{}, {}, {}].map((_, index) => (
                <div
                  key={index}
                  className={`underline-item ${index === 1 ? "active" : ""}`}
                />
              ))}
            </div>

            // <div className="bottom-sec">
            // <div className="carousel" style={{margin:"0"}}>
            //   <div className="carousel__container">
            //     {isGalleryImages?.map((gallery, imageindex) => {
            //       const active = galleryPopupData?.mediaId;
            //       return (
            //         <div
            //           className={`carousel__card ${active ? "active" : ""}`}
            //           key={imageindex}
            //           // onClick={() => handleActive(imageindex)}
            //         >
            //           <img src={gallery?.url} alt="carousel-img" />
            //           <div className="shadow"></div>
            //         </div>
            //       );
            //     })}
            //   </div>
            // </div>
            // </div>
          )}
        </div>
        <div className="custom-slider__content" data-scrollable="true">
          <div className="custom-slider__content__main">
            <div className="custom-slider__content__section">
              {galleryPopupData !== undefined ? (
                <div className="custom-slider__content__section__heading">
                  <div className="custom-slider__content__section__heading__left">
                    {(feedData?.toolset?.split(",")[0] === "text" ||
                      dynamicValue1 === "TEXT") && (
                      <img src={texti} alt="toolset-icon" />
                    )}
                    {(feedData?.toolset?.split(",")[0] === "design" ||
                      dynamicValue1 === "DESIGN") && (
                      <img src={designi} alt="toolset-icon" />
                    )}
                    {(feedData?.toolset?.split(",")[0] === "image" ||
                      dynamicValue1 === "IMAGE") && (
                      <>
                        {feedData?.controlLevel === null ? (
                          <img src={inpainti} alt="toolset-icon" />
                        ) : (
                          <img src={shapei} alt="toolset-icon" />
                        )}
                      </>
                    )}
                    {(feedData?.toolset?.split(",")[0] === "video" ||
                      dynamicValue1 === "VIDEO") && (
                      <img src={video} alt="toolset-icon" />
                    )}
                    {/*feedData?.toolset === "eraser" && (
                      <img src={eraseri} alt="toolset-icon" />
                    )*/}
                    {(feedData?.toolset?.split(",")[0] === "eraser" ||
                      dynamicValue1 === "ERASER") && (
                      <img src={eraseri} alt="toolset-icon" />
                    )}
                    {feedData?.toolset?.split(",")[0] === "upscale" && (
                      <>
                        {feedData?.toolset?.split(",")[1] === "people" ? (
                          <img src={character} alt="toolset-icon" />
                        ) : feedData?.toolset?.split(",")[1] ===
                          "outpainting" ? (
                          <img src={extend} alt="toolset-icon" />
                        ) : (
                          <img src={HDi} alt="toolset-icon" />
                        )}
                      </>
                    )}
                    {feedData?.toolset?.split(",")[0] === "image" ||
                    dynamicValue1 === "IMAGE" ? (
                      feedData?.controlLevel === null ? (
                        <h3>{t("inpaint")}</h3>
                      ) : (
                        <h3>{t("shape")}</h3>
                      )
                    ) : (
                      <>
                        {feedData?.toolset?.split(",")[0] === "upscale" ? (
                          <h3>
                            {feedData?.toolset?.split(",")[1] === "people"
                              ? t("enhance_option3")
                              : feedData?.toolset?.split(",")[1] ===
                                "outpainting"
                              ? t("enhance_option1")
                              : t("enhance_option2")}
                          </h3>
                        ) : (
                          <h3>{t(popupDynamicValue1)}</h3>
                        )}
                      </>
                    )}
                  </div>
                  {feedData?.toolset?.split(",")[0] !== "upscale" &&
                  feedData?.toolset?.split(",")[1] ? (
                    <div className="custom-slider__content__section__heading__right">
                      <h3>{t("upscale")}</h3>
                    </div>
                  ) : (
                    ""
                  )}
                  {feedData?.originalImage &&
                    feedData?.toolset?.split(",")[0] !== "image" && (
                      <div className="custom-slider__content__section__heading__right">
                        <h3>{t("base_popup")}</h3>
                      </div>
                    )}
                </div>
              ) : (
                <div className="custom-slider__content__section__heading">
                  <div className="custom-slider__content__section__heading__left">
                    {feedData?.toolset?.split(",")[0] === "text" && (
                      <img src={texti} alt="toolset-icon" />
                    )}
                    {feedData?.toolset?.split(",")[0] === "design" && (
                      <img src={designi} alt="toolset-icon" />
                    )}
                    {feedData?.toolset?.split(",")[0] === "image" && (
                      <>
                        {feedData?.controlLevel === null ? (
                          <img src={inpainti} alt="toolset-icon" />
                        ) : (
                          <img src={shapei} alt="toolset-icon" />
                        )}
                      </>
                    )}
                    {feedData?.toolset?.split(",")[0] === "eraser" && (
                      <img src={eraseri} alt="toolset-icon" />
                    )}
                    {feedData?.toolset?.split(",")[0] === "video" && (
                      <img src={video} alt="toolset-icon" />
                    )}
                    {feedData?.toolset?.split(",")[0] === "upscale" && (
                      <>
                        {feedData?.toolset?.split(",")[1] === "people" ? (
                          <img src={character} alt="toolset-icon" />
                        ) : feedData?.toolset?.split(",")[1] ===
                          "outpainting" ? (
                          <img src={extend} alt="toolset-icon" />
                        ) : (
                          <img src={HDi} alt="toolset-icon" />
                        )}
                      </>
                    )}
                    {feedData?.toolset?.split(",")[0] === "image" ? (
                      feedData?.controlLevel === null ? (
                        <h3>{t("inpaint")}</h3>
                      ) : (
                        <h3>{t("shape")}</h3>
                      )
                    ) : (
                      <>
                        {feedData?.toolset?.split(",")[0] === "upscale" ? (
                          <h3>
                            {feedData?.toolset?.split(",")[1] === "people"
                              ? t("enhance_option3")
                              : feedData?.toolset?.split(",")[1] ===
                                "outpainting"
                              ? t("enhance_option1")
                              : t("enhance_option2")}
                          </h3>
                        ) : (
                          <h3>{t(dynamicValue1)}</h3>
                        )}
                      </>
                    )}
                  </div>
                  {feedData?.toolset?.split(",")[0] !== "upscale" &&
                  feedData?.toolset?.split(",")[1] ? (
                    <div className="custom-slider__content__section__heading__right">
                      <h3>{t("upscale")}</h3>
                    </div>
                  ) : (
                    ""
                  )}
                  {feedData?.originalImage &&
                  feedData?.toolset?.split(",")[0] !== "image" ? (
                    <div className="custom-slider__content__section__heading__right">
                      <h3>{t("base_popup")}</h3>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {feedData?.toolset?.split(",")[0] === "video" ? (
                <>
                  {feedData?.enteredText !== null &&
                    feedData?.enteredText !== "" &&
                    feedData?.enteredText !== " " &&
                    feedData?.text !== null &&
                    feedData?.text !== "" &&
                    feedData?.text !== " " && (
                      <div className="custom-slider__content__section__main">
                        <div className="custom-slider__content__section__main__div">
                          {feedData?.referenceImage &&
                            feedData?.referenceImage?.length !== 0 && (
                              <div className="custom-slider__content__section__main__div__tab">
                                <div className="custom-slider__content__section__main__div__tab__heading">
                                  <h5 className="tags">{t("first_frame")}</h5>
                                </div>
                                <div className="custom-slider__content__section__main__div__tab__content">
                                  <div className="custom-slider__content__section__main__div__tab__content__sec">
                                    {feedData?.referenceImage?.map(
                                      (ref, index) => (
                                        <span key={index}>{ref?.weight}</span>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          {feedData?.enteredText !== null &&
                            feedData?.enteredText !== "" &&
                            feedData?.enteredText !== " " && (
                              <div className="custom-slider__content__section__main__div__tab pad">
                                <div className="custom-slider__content__section__main__div__tab__heading">
                                  <h5>{t("prompt")}</h5>
                                </div>
                                <div className="custom-slider__content__section__main__div__tab__content">
                                  <div className="custom-slider__content__section__main__div__tab__content__sec text">
                                    <h6>{feedData?.enteredText}</h6>
                                  </div>
                                </div>
                              </div>
                            )}
                          {feedData?.text !== null &&
                            feedData?.text !== "" &&
                            feedData?.text !== " " &&
                            feedData?.enteredText && <hr />}
                          {feedData?.text !== null &&
                            feedData?.text !== "" &&
                            feedData?.text !== " " && (
                              <div className="custom-slider__content__section__main__div__tab">
                                <div className="custom-slider__content__section__main__div__tab__heading">
                                  <h5>{t("GPT_output")}</h5>
                                </div>
                                <div className="custom-slider__content__section__main__div__tab__content">
                                  <div className="custom-slider__content__section__main__div__tab__content__sec text">
                                    <p>{feedData?.text}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  {feedData?.prompts !== null &&
                    feedData?.prompts !== "" &&
                      feedData?.prompts !== " " && 
                      <div className="custom-slider__content__section__main">
                        <div className="custom-slider__content__section__main__div">
                          <div className="custom-slider__content__section__main__div__tab">
                            <div className="custom-slider__content__section__main__div__tab__heading">
                              <h5 className="tags">{t("camera_movement")}</h5>
                            </div>
                            <div className="custom-slider__content__section__main__div__tab__content">
                              <div className="custom-slider__content__section__main__div__tab__content__sec tab">
                                <p>{feedData?.prompts}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                </>
              ) : (
                <div className="custom-slider__content__section__main">
                  {feedData?.toolset?.split(",")[0] !== "eraser" &&
                    (feedData?.toolset?.split(",")[0] !== "upscale" ||
                      (feedData?.toolset?.split(",")[0] === "upscale" &&
                        feedData?.toolset?.split(",")[1] === "outpainting" &&
                        (!isSecondValueNoStyle ||
                          feedData?.enteredText ||
                          (feedData?.referenceImage &&
                            feedData?.referenceImage?.length !== 0) ||
                          feedData?.privateModel))) && (
                      <div className="custom-slider__content__section__main__div">
                        {!isSecondValueNoStyle && (
                          <>
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">{t("Template")}</h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  {feedData?.prompts
                                    ?.split(",")
                                    ?.map((prompt, index) => (
                                      <span key={index}>{prompt.trim()}</span>
                                    ))}
                                  <span>{feedData?.style}</span>
                                </div>
                              </div>
                            </div>
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">{t("graphic_style")}</h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  <span>{feedData?.graphicStyle}</span>
                                </div>
                              </div>
                            </div>
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">{t("camera")}</h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  <span>{feedData?.camera}</span>
                                </div>
                              </div>
                            </div>
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">{t("atmosphere")}</h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  {feedData?.atmosphere?.split(",")[1] && (
                                    <span>
                                      {feedData?.atmosphere?.split(",")[1]}
                                    </span>
                                  )}
                                  <span>
                                    {feedData?.atmosphere?.split(",")[0]}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {feedData?.referenceImage &&
                          feedData?.referenceImage?.length !== 0 && (
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">
                                  {t("atmosphere_shape_ref")}
                                </h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  {feedData?.referenceImage?.map(
                                    (ref, index) => (
                                      <span key={index}>{ref?.weight}</span>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        {feedData?.enteredText !== null &&
                          feedData?.enteredText !== "" && (
                            <div className="custom-slider__content__section__main__div__tab pad">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5>{t("prompt")}</h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec text">
                                  <h6>{feedData?.enteredText}</h6>
                                </div>
                              </div>
                            </div>
                          )}
                        {feedData?.text !== null &&
                          feedData?.text !== "" &&
                          feedData?.enteredText && <hr />}
                        {feedData?.text !== null && feedData?.text !== "" && (
                          <div className="custom-slider__content__section__main__div__tab">
                            <div className="custom-slider__content__section__main__div__tab__heading">
                              <h5>{t("GPT_output")}</h5>
                            </div>
                            <div className="custom-slider__content__section__main__div__tab__content">
                              <div className="custom-slider__content__section__main__div__tab__content__sec text">
                                <p>{feedData?.text}</p>
                              </div>
                            </div>
                          </div>
                        )}
                        {feedData?.enteredAdditionalNegPrompt && (
                          <div className="custom-slider__content__section__main__div__tab pad">
                            <div className="custom-slider__content__section__main__div__tab__heading">
                              <h5>{t("negative_prompt")}</h5>
                            </div>
                            <div className="custom-slider__content__section__main__div__tab__content">
                              <div className="custom-slider__content__section__main__div__tab__content__sec text">
                                <h6>{feedData?.enteredAdditionalNegPrompt}</h6>
                              </div>
                            </div>
                          </div>
                        )}
                        {feedData?.additionalNegativePrompt &&
                          feedData?.enteredAdditionalNegPrompt && <hr />}
                        {feedData?.additionalNegativePrompt && (
                          <div className="custom-slider__content__section__main__div__tab">
                            <div className="custom-slider__content__section__main__div__tab__heading">
                              <h5>{t("GPT_output")}</h5>
                            </div>
                            <div className="custom-slider__content__section__main__div__tab__content">
                              <div className="custom-slider__content__section__main__div__tab__content__sec text">
                                <p>{feedData?.additionalNegativePrompt}</p>
                              </div>
                            </div>
                          </div>
                        )}
                        {feedData?.privateModel && (
                          <div className="custom-slider__content__section__main__div__tab1 pad">
                            <div className="custom-slider__content__section__main__div__tab__heading1">
                              <h5>{t("private_custom_model")}</h5>
                            </div>
                            <div className="custom-slider__content__section__main__div__tab__content">
                              <div className="custom-slider__content__section__main__div__tab__content__sec text">
                                <span>
                                  {feedData?.privateModel?.split(";")[1]}:
                                  {feedData?.privateModel?.split(";")[3]}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                </div>
              )}
              {feedData?.toolset?.split(",")[0] === "video" && (
                <div className="custom-slider__content__section__main">
                  <div className="custom-slider__content__section__ref__div">
                    <div
                      className="custom-slider__content__section__ref__div__main"
                      onClick={handleRefPopup}
                    >
                      <h6>{t("first_frame")}</h6>
                      <img src={feedData?.baseImage} alt="ref-img" />
                    </div>
                  </div>
                </div>
              )}
              {feedData?.toolset?.split(",")[0] === "eraser" ||
              (feedData?.toolset?.split(",")[0] === "image" &&
                feedData?.controlLevel === null) ? (
                <div className="custom-slider__content__section__main">
                  <div className="custom-slider__content__section__main__div">
                    {feedData?.imageCount && (
                      <div className="custom-slider__content__section__main__div__tab">
                        <div className="custom-slider__content__section__main__div__tab__heading">
                          <h5 className="tags">{t("image_count")}</h5>
                        </div>
                        <div className="custom-slider__content__section__main__div__tab__content">
                          <div className="custom-slider__content__section__main__div__tab__content__sec">
                            <span>{feedData?.imageCount}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {(feedData?.toolset?.split(",")[0] !== "image"
                    ? feedData?.imageRatio !== null ||
                      feedData?.controlLevel !== null
                    : feedData?.inputFidelity !== null) &&
                    feedData?.toolset?.split(",")[1] !== "outpainting" && (
                      <div className="custom-slider__content__section__main">
                        <div className="custom-slider__content__section__main__div">
                          {feedData?.orientation && (
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">{t("orientation")}</h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  <span>
                                    {feedData?.orientation === "0"
                                      ? t("horizontal")
                                      : t("vertical")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          {feedData?.imageRatio && (
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">{t("image_ratio")}</h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  <span>{feedData?.imageRatio}</span>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* {feedData?.toolset?.split(",")[0] === "image" &&
                            feedData?.inputFidelity !== null &&
                            feedData?.inputFidelity !== 0 &&
                            feedData?.inputFidelity !== null && (
                              <div className="custom-slider__content__section__main__div__tab">
                                <div className="custom-slider__content__section__main__div__tab__heading">
                                  <h5 className="tags">
                                    {t("modification_level")}
                                  </h5>
                                </div>
                                <div className="custom-slider__content__section__main__div__tab__content">
                                  <div className="custom-slider__content__section__main__div__tab__content__sec">
                                    <span>{feedData?.inputFidelity}</span>
                                  </div>
                                </div>
                              </div>
                            )} */}
                          {/* {feedData?.controlLevel !== null &&
                            feedData?.imageType !== null &&
                            feedData?.imageType !== "null" &&
                            feedData?.imageType && (
                              <div className="custom-slider__content__section__main__div__tab">
                                <div className="custom-slider__content__section__main__div__tab__heading">
                                  <h5 className="tags">
                                    {t("feed_control_type")}
                                  </h5>
                                </div>
                                <div className="custom-slider__content__section__main__div__tab__content">
                                  <div className="custom-slider__content__section__main__div__tab__content__sec">
                                    <span>{feedData?.imageType}</span>
                                  </div>
                                </div>
                              </div>
                            )} */}
                          {feedData?.controlLevel !== null && (
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">
                                  {t("feed_control_level")}
                                </h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  <span>{feedData?.controlLevel}</span>
                                </div>
                              </div>
                            </div>
                          )}
                          {feedData?.toolset?.split(",")[0] === "image" &&
                            feedData?.inputFidelity !== null &&
                            feedData?.inputFidelity !== 0 &&
                            feedData?.inputFidelity !== null && (
                              <div className="custom-slider__content__section__main__div__tab">
                                <div className="custom-slider__content__section__main__div__tab__heading">
                                  <h5 className="tags">
                                    {t("preserve_material")}
                                  </h5>
                                </div>
                                <div className="custom-slider__content__section__main__div__tab__content">
                                  <div className="custom-slider__content__section__main__div__tab__content__sec">
                                    <span>{feedData?.inputFidelity}</span>
                                  </div>
                                </div>
                              </div>
                            )}
                          {feedData?.toolset?.split(",")[0] !== "image" &&
                            feedData?.inputFidelity !== null &&
                            feedData?.inputFidelity !== 0 && (
                              <div className="custom-slider__content__section__main__div__tab">
                                <div className="custom-slider__content__section__main__div__tab__heading">
                                  <h5 className="tags">
                                    {t("preserve_material_new")}
                                  </h5>
                                </div>
                                <div className="custom-slider__content__section__main__div__tab__content">
                                  <div className="custom-slider__content__section__main__div__tab__content__sec">
                                    <span>{feedData?.inputFidelity}</span>
                                  </div>
                                </div>
                              </div>
                            )}
                          {feedData?.toolset?.split(",")[0] === "design" && (
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">
                                  {t("image_resolution")}
                                </h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  <span>
                                    {feedData?.megapixels} {t("megapixels")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="custom-slider__content__section__main__div">
                          {(feedData?.toolset?.split(",")[0] === "design" ||
                            "text") &&
                            feedData?.imageCount && (
                              <div className="custom-slider__content__section__main__div__tab">
                                <div className="custom-slider__content__section__main__div__tab__heading">
                                  <h5 className="tags">{t("image_count")}</h5>
                                </div>
                                <div className="custom-slider__content__section__main__div__tab__content">
                                  <div className="custom-slider__content__section__main__div__tab__content__sec">
                                    <span>{feedData?.imageCount}</span>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  {feedData?.toolset?.split(",")[0] === "upscale" && (
                    <div className="custom-slider__content__section__main">
                      <div className="custom-slider__content__section__main__div">
                        {feedData?.toolset?.split(",")[1] === "creative" ||
                        !feedData?.toolset?.split(",")[1] ? (
                          <>
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">{t("enhance_option2")}</h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  <span>
                                  {feedData?.toolset?.split(",")[1] === "creative" ?
                                    (feedData?.prompts === "Enhanced detail" || feedData?.prompts === "细节增强" ?
                                      t("upscale_creative") : 
                                      t("upscale_creative_enhanced")) 
                                    : t("upscale_basic")}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">{t("image_count")}</h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  <span>{feedData?.imageCount}</span>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {feedData?.toolset?.split(",")[1] ===
                              "outpainting" && (
                              <div className="custom-slider__content__section__main__div__tab">
                                <div className="custom-slider__content__section__main__div__tab__heading">
                                  <h5 className="tags">
                                    {feedData?.verticalPercentage !== 0
                                      ? t("vertical")
                                      : t("horizontal")}
                                  </h5>
                                </div>
                                <div className="custom-slider__content__section__main__div__tab__content">
                                  <div className="custom-slider__content__section__main__div__tab__content__sec">
                                    <span>
                                      +
                                      {feedData?.verticalPercentage !== 0
                                        ? feedData?.verticalPercentage
                                        : feedData?.horizontalPercentage}
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="custom-slider__content__section__main__div__tab">
                              <div className="custom-slider__content__section__main__div__tab__heading">
                                <h5 className="tags">{t("image_count")}</h5>
                              </div>
                              <div className="custom-slider__content__section__main__div__tab__content">
                                <div className="custom-slider__content__section__main__div__tab__content__sec">
                                  <span>{feedData?.imageCount}</span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="custom-slider__content__section__ref">
                {feedData?.referenceImage &&
                  feedData?.referenceImage?.length !== 0 && (
                    <div className="custom-slider__content__section__ref__div">
                      <div
                        className="custom-slider__content__section__ref__div__main"
                        onClick={handleRefPopup}
                      >
                        <h6>{t("atmosphere_shape_ref")}</h6>
                        <img
                          src={feedData?.referenceImage[0]?.url}
                          alt="ref-img"
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="custom-slider__content__bottom__container">
              <div className="custom-slider__content__bottom__container__left">
                {(showAllIcons || showDownloadIcon) && (
                  <div className="custom-slider__content__bottom__container__left__team">
                    <h4>{formatTeamNames(feedData?.teamName, 13)}</h4>
                    <h5>{formatTeamNames(feedData?.projectName, 13)}</h5>
                  </div>
                )}
              </div>
              <div className="custom-slider__content__bottom__container__right">
                <div className="custom-slider__content__bottom__container__right__user">
                  <div className="custom-slider__content__bottom__container__right__user__content">
                    <h4>
                      {feedData?.username && feedData.username.length > 13
                        ? `${feedData.username.substring(0, 13)}...`
                        : feedData?.username || "User name"}
                    </h4>
                    {galleryPopupData === undefined ? (
                      <p>
                        {formatDate(feedData?.createdAt) ||
                          "23.03.2023 - 09.23"}
                      </p>
                    ) : (
                      <p>{galleryPopupData?.created}</p>
                    )}
                  </div>
                  <div className="custom-slider__content__bottom__container__right__user__icon">
                    <img src={feedData?.avatarUrl || user} alt="user-icon" />
                  </div>
                </div>
                {showAllIcons && (
                  <>
                    {feedData?.stealth === true ? (
                      <div className="custom-slider__content__bottom__container__right__mode">
                        <img src={pro} alt="icon" />
                        <h4>{t("Private_mode")}</h4>
                      </div>
                    ) : (
                      <div className="custom-slider__content__bottom__container__right__mode">
                        <img src={relax} alt="icon" />
                        <h4>{t("Public_mode")}</h4>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WorkSpaceFeedPopUp = ({
  projectMedias,
  mediaIndex,
  closePopup,
  isButtonDisabled,
  setIsButtonDisabled,
  setIsLimitPopup,
  handleClosePopupGlobal,
  setGlobalErrorMessage,
  galleryPopupData,
  isGalleryCurrentIndex,
  handlePopupclose,
  handlePopupShowSlider,
  popupShowSlider,
  popupDynamicValue1,
  popupDynamicValue2,
  showStealth,
  isGalleryImages,
  showAllIcons,
  setDeletedMediaId,
  deletedMediaId,
  isMaxCount,
  isPrivilageCount,
  isMaxButtonClick,
  isFavoriteImages,
  setIsFavoriteImages,
  handleGenerateUpscale,
  setIsUploadScaled,
  setTemplateDisable,
  showDownloadIcon = false,
  showDeleteIcon = false,
  handleWorkspacePopupUp,
  handleWorkspacePopupDown,
  generatedAllImages,
  generationIndex,
  setHighlightedMediaId,
  setGalleryPopupData,
  setIsGalleryCurrentIndex,
  isWorkspaceUpAvailable,
  isWorkspaceDownAvailable,
}) => {
  projectMedias = projectMedias || []; // will be undefined from gallery
  const createDefaultMask = useCreateDefaultMask();
  const { updateToolsetData } = useContext(ToolsetDataContext);
  const { editToolsetData, updateEditToolsetData } =
    useContext(EditToolsetContext);
  const { updateUpscaleToolsetData } = useContext(UpscaleToolsetContext);
  const { updateAtmosphereRefData } = useContext(AtmosphereRefContext);
  const { updateRenderToolsetData } = useContext(RenderToolsetContext);
  const { updateSocketData } = useContext(UserSocketContext);
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));

  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isActiveIndex, setIsActiveIndex] = useState();
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const [loader, setLoader] = useState(false);
  const [refPopup, setRefPopup] = useState(false);

  const handleRefPopup = () => {
    setRefPopup(!refPopup);
  };

  const handleRefPopupClose = () => {
    setRefPopup(false);
  };

  const handleUpscaleClick = async (
    activeImageIndex,
    bool,
    isUpscaleRef = false
  ) => {
    /*if (isMaxCount >= isPrivilageCount || isMaxCount >= 4) {
      isMaxButtonClick();
      closePopup();
      handleRefPopupClose();
    } else {*/
    const imageType = "base-image";
    localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
    updateToolsetData({ toolsetActive: 3 });
    const newToolsetData = {
      toolsetActive: 3,
    };
    localStorage.setItem(
      `activeToolset_${lastPart}`,
      JSON.stringify(newToolsetData)
    );
    handleRefPopupClose();
    setIsLoader(true);
    const activeImage = projectMedias[activeImageIndex];

    const url = bool //either send the reference image or send the base image for 4x upscale
      ? activeImage.url
      : projectMedias[activeImageIndex]?.referenceImage[0]?.url;

    const image = new Image();
    if (!url) return;
    image.src = url;

    updateUpscaleToolsetData({
      isUpScaleURLImgUrl: url,
      feedUpBase64: null,
    });
    const upscaleUpdate = {
      feedUpBase64: null,
      isUpScaleURLImgUrl: url,
    };
    localStorage.setItem(
      `upscaleBaseimg_${lastPart}`,
      JSON.stringify(upscaleUpdate)
    );

    const updatedMediaId = activeImage.mediaId;
    let width, height;
    image.onload = function () {
      width = this.width;
      height = this.height;
      if (width * height >= ENHANCE_MAX_PIXELS) {
        // removed 2024/09/20 margin to avoid running the retrival, might need it back for optminization purposes
        // yes this path is slow as fuck but there is really nothing to do about it
        CallAwsUrl((res) => {
          if (res.status === 200) {
            cropImageFromBase64ForUpscale(
              handleBase64Data(res.data),
              3,
              (resizedImageUrl, feedUpBase64 = null) => {
                updateUpscaleToolsetData({
                  isUpScaleURLImgUrl: resizedImageUrl,
                  feedUpBase64,
                  isMediaId: updatedMediaId,
                  //isUpscaleFeedApi: isUpscaleRef ? false : feedUpBase64 !== null,
                });
                const upscaleUpdate = {
                  feedUpBase64: {
                    path: url,
                    width,
                    height,
                  },
                  isUpScaleURLImgUrl: url,
                };
                localStorage.setItem(
                  `upscaleBaseimg_${lastPart}`,
                  JSON.stringify(upscaleUpdate)
                );
                setIsLoader(false);
                closePopup();
                handleRefPopupClose();
                /*if (isUpscaleRef) {
                    handleGenerateUpscale();
                  }*/
              }
            );
          }
        }, url);
      } else {
        updateUpscaleToolsetData({
          feedUpBase64: {
            path: url,
            width,
            height,
          },
          isUpScaleURLImgUrl: url,
          isMediaId: updatedMediaId,
          //isUpscaleFeedApi: isUpscaleRef ? false : true,
        });
        const upscaleUpdate = {
          feedUpBase64: {
            path: url,
            width,
            height,
          },
          isUpScaleURLImgUrl: url,
        };
        localStorage.setItem(
          `upscaleBaseimg_${lastPart}`,
          JSON.stringify(upscaleUpdate)
        );
        setIsLoader(false);
        closePopup();
        /*if (isUpscaleRef) {
            handleGenerateUpscale();
          }*/
      }
    };
    image.onerror = function () {
      console.error("Failed to load image from:", url);
      //};
    };
  };

  const cropImageFromBase64ForUpscale = (imageUrl, toolset, updateFunction) => {
    let bool = false;
    const img = new Image();
    img.src = imageUrl;

    img.onload = function () {
      let resizedWidth, resizedHeight, scale;
      let resizedImageUrl = processImage(
        img,
        computePostProcessedDimension,
        toolset,
        (dimensions) => {
          resizedWidth = dimensions.resizedWidth;
          resizedHeight = dimensions.resizedHeight;
          scale = dimensions.scale;
        }
      );
      if (scale >= SCALE_LIMIT) {
        setIsUploadScaled(true);
      }
      fetch(resizedImageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const resizedImageFile = new File([blob], "resized_image.jpeg", {
            type: "image/jpeg",
          });
          uploadFile(
            "base-image",
            bool,
            resizedImageFile,
            async (data) => {
              updateFunction(resizedImageUrl, data);
              const upscaleUpdate = {
                feedUpBase64: data,
                isUpScaleURLImgUrl: resizedImageUrl,
              };
              localStorage.setItem(
                `upscaleBaseimg_${lastPart}`,
                JSON.stringify(upscaleUpdate)
              );
            },
            () => setIsButtonDisabled(false),
            (error) => {
              handleRateLimitPopup(error);
              const upscaleUpdate = {
                feedUpBase64: null,
                isUpScaleURLImgUrl: null,
              };
              localStorage.setItem(
                `upscaleBaseimg_${lastPart}`,
                JSON.stringify(upscaleUpdate)
              );
            },
            updateSocketData
          );
        })
        .catch((error) => {
          console.error("Error converting Data URL to File:", error);
        });
    };

    img.onerror = function () {
      console.error("Failed to load image.");
    };
  };

  const cropImageFromBase64 = (
    imageUrl,
    toolset,
    CNImageBlob,
    updateFunction,
    selection
  ) => {
    let bool = false;
    const img = new Image();
    img.src = imageUrl;

    img.onload = function () {
      let resizedWidth, resizedHeight, scale;
      let resizedImageUrl = processImage(
        img,
        computePostProcessedDimension,
        toolset,
        (dimensions) => {
          resizedWidth = dimensions.resizedWidth;
          resizedHeight = dimensions.resizedHeight;
          scale = dimensions.scale;
        }
      );
      if (scale >= SCALE_LIMIT) {
        setIsUploadScaled(true);
      }
      const maskCreationPromise = createDefaultMask(
        resizedImageUrl,
        resizedWidth,
        resizedHeight,
        CNImageBlob,
        selection === "2",
        updateSocketData
      );

      fetch(resizedImageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const resizedImageFile = new File([blob], "resized_image.jpeg", {
            type: "image/jpeg",
          });
          uploadFile(
            "base-image",
            bool,
            resizedImageFile,
            async (data) => {
              const defaultMask = await maskCreationPromise;
              resetMask(0);
              updateFunction(
                resizedImageUrl,
                data,
                defaultMask.visualizedMaskBase64,
                defaultMask.actualMaskData
              );
            },
            () => setIsButtonDisabled(false),
            (error) => handleRateLimitPopup(error),
            updateSocketData
          );
        })
        .catch((error) => {
          console.error("Error converting Data URL to File:", error);
        });
    };

    img.onerror = function () {
      console.error("Failed to load image.");
    };
  };

  const handleRateLimitPopup = (error) => {
    updateEditToolsetData({ uploadedBaseImage: null });

    setIsLimitPopup(true);
    setGlobalErrorMessage(error.message);
  };

  const handleUpdateBaseImage = async (activeImageIndex, bool) => {
    updateToolsetData({ toolsetActive: 2 });
    const imageType = "base-image";
    localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
    const newToolsetData = {
      toolsetActive: 2,
    };
    localStorage.setItem(
      `activeToolset_${lastPart}`,
      JSON.stringify(newToolsetData)
    );
    localStorage.setItem(`selection_${lastPart}`, "1");
    const check = {
      isDesign: false,
    };
    setTemplateDisable(false);
    localStorage.setItem(
      `designLibraryDisable_${lastPart}`,
      JSON.stringify(check)
    );
    setIsButtonDisabled(true);
    const selection = localStorage.getItem(`selection_${lastPart}`);

    const activeImage = projectMedias[activeImageIndex];
    const url = bool
      ? activeImage.url
      : projectMedias[activeImageIndex]?.referenceImage[0]?.url;
    if (!url) return;
    updateEditToolsetData({
      uploadedBaseImage: url,
      feedImageBase64: null,
      maskImageData: url,
      maskImageBase64: null,
      isFromDesignActivated: false,
      cnImage: null,
      cnImageBase64: null,
      originalCnImage: null,
      originalCnImageBase64: null,
    });

    let CNImageBlob = null;

    const uploadBaseImage = (CNImageUrl = null, initialCNImageUrl = null) => {
      CallAwsUrl((res) => {
        if (res.status === 200) {
          cropImageFromBase64(
            handleBase64Data(res.data),
            2,
            CNImageBlob,
            (
              resizedImageUrl,
              feedImageBase64 = null,
              maskImageData = null,
              maskImageBase64 = null
            ) => {
              updateEditToolsetData({
                isFromDesignActivated: selection === "2",
                uploadedBaseImage: resizedImageUrl,
                feedImageBase64,
                maskImageData,
                maskImageBase64,
                cnImageBase64: CNImageUrl ? { path: CNImageUrl } : null,
                originalCnImageBase64: CNImageUrl
                  ? { path: initialCNImageUrl || CNImageUrl }
                  : null,
              });
            },
            selection
          );
        }
      }, url);
    };

    if (activeImage?.toolset === "design") {
      //update ahead of time to ensure user will not need to wait, below is in url, so there still is a bit of delay
      CallAwsUrl((res) => {
        //for CN image
        CNImageBlob = handleBase64Data(res.data);
        updateEditToolsetData({
          cnImage: CNImageBlob,
          originalCnImage: CNImageBlob,
        });
        uploadBaseImage(activeImage?.baseImage);
      }, activeImage?.baseImage);
    } else if (activeImage?.toolset === "image" && activeImage?.originalImage) {
      CallAwsUrl((res) => {
        //for CN image
        CNImageBlob = handleBase64Data(res.data);
        updateEditToolsetData({
          cnImage: CNImageBlob,
          originalCnImage: CNImageBlob,
        });
        uploadBaseImage(
          activeImage?.originalImage,
          activeImage?.initialCNImage
        );
      }, activeImage?.originalImage);
    } else {
      // just upload base image
      uploadBaseImage();
    }

    closePopup();
    handleRefPopupClose();
  };

  const updatePreUploadState = (toolset, uploadedUrl) => {
    switch (toolset) {
      case 0:
      case 1:
        updateRenderToolsetData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const renderUpdate = {
          feedDesignBase64: null,
          uploadedBaseImageUrl: uploadedUrl,
        };
        localStorage.setItem(
          `designBaseimg_${lastPart}`,
          JSON.stringify(renderUpdate)
        );
        break;
      case 2:
        updateEditToolsetData({
          uploadedBaseImage: uploadedUrl,
          feedImageBase64: null,
          maskImageData: uploadedUrl,
          maskImageBase64: null,
          isFromDesignActivated: false,
          cnImage: null,
          cnImageBase64: null,
          originalCnImage: null,
          originalCnImageBase64: null,
        });
        break;
      case 3:
        updateUpscaleToolsetData({
          isUpScaleURLImgUrl: uploadedUrl,
          feedUpBase64: null,
        });
        const upscaleUpdate = {
          feedUpBase64: null,
          isUpScaleURLImgUrl: uploadedUrl,
        };
        localStorage.setItem(
          `upscaleBaseimg_${lastPart}`,
          JSON.stringify(upscaleUpdate)
        );
        break;
      case 10: //for controlnet inpainting
        updateEditToolsetData({
          isFromDesignActivated: true,
          cnImage: uploadedUrl,
          cnImageBase64: null,
          originalCnImage: uploadedUrl,
          originalCnImageBase64: null,
          // maskImageBase64: null,
        });
        break;
      case 11:
        updateAtmosphereRefData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const check = JSON.parse(
          localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
        );
        if (check[0]) {
          check[0].feedDesignBase64 = null;
          check[0].uploadedBaseImageUrl = uploadedUrl;
        }
        localStorage.setItem(
          `atmosphereRefLocal_${lastPart}`,
          JSON.stringify(check)
        );
        break;
      case 12:
        updateAtmosphereRefData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const check1 = JSON.parse(
          localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
        );
        if (check1[1]) {
          check1[1].feedDesignBase64 = null;
          check1[1].uploadedBaseImageUrl = uploadedUrl;
        }
        localStorage.setItem(
          `atmosphereRefLocal_${lastPart}`,
          JSON.stringify(check1)
        );
        break;
      default:
        break;
    }
    if (toolset === 11 || toolset === 12) {
      let check =
        JSON.parse(localStorage.getItem(`isTemplate_${lastPart}`)) || {};
      if (!check?.isAtmosphere) {
        check.isAtmosphere = true;
      }
      localStorage.setItem(`isTemplate_${lastPart}`, JSON.stringify(check));
    }
    closePopup();
    handleRefPopupClose();
  };

  const handleUpdateRefrenceImage = async (
    activeImageIndex,
    toolset,
    bool,
    bool1 = false
  ) => {
    let imageType = "base-image";
    if (toolset === 1) {
      imageType = "cn-image";
    } else if (toolset === 2) {
      imageType = "base-image";
    } else if (toolset === 3) {
      imageType = "base-image";
    } else if (toolset === 10) {
      imageType = "cn-image";
    } else if (toolset === 11 || toolset === 12) {
      imageType = "cn-image";
    }
    localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
    const selectedToolset =
      toolset !== null && toolset !== undefined ? toolset : isActiveToolset;

    if (toolset === 10 && !editToolsetData.feedImageBase64) return;
    localStorage.setItem(`imageType_${lastPart}`, JSON.stringify(imageType));
    setIsButtonDisabled(true);

    const activeImage = projectMedias[activeImageIndex];
    const url = bool
      ? bool1
        ? activeImage.baseImage
        : activeImage.url
      : projectMedias[activeImageIndex]?.referenceImage[0]?.url;
    const isUpscale = activeImage?.toolset?.includes("upscale");
    if (!url) return;
    updatePreUploadState(toolset, url);
    if (toolset === 10) {
      CallAwsUrl((res) => {
        //for CN image
        updateEditToolsetData({
          cnImage: handleBase64Data(res.data),
          originalCnImage: handleBase64Data(res.data),
        });
        //now handle CN image and upload
        CallAwsUrl((res) => {
          handleBaseImageData(selectedToolset, handleBase64Data(res.data));
        }, url);
      }, url);
    } else if (activeImage?.toolset === "design") {
      //update ahead of time to ensure user will not need to wait, below is in url, so there still is a bit of delay
      CallAwsUrl((res) => {
        //for CN image
        const CNImageBlob = handleBase64Data(res.data);
        updateEditToolsetData({
          cnImage: CNImageBlob,
          originalCnImage: CNImageBlob,
        });
        //now handle base image and upload
        CallAwsUrl((res) => {
          handleBaseImageData(
            selectedToolset,
            handleBase64Data(res.data),
            CNImageBlob,
            activeImage?.baseImage
          );
        }, url);
      }, activeImage?.baseImage);
    } else if (activeImage?.toolset === "image" && activeImage?.originalImage && toolset !== 11) {
      CallAwsUrl((res) => {
        //for CN image
        const CNImageBlob = handleBase64Data(res.data);
        updateEditToolsetData({
          cnImage: CNImageBlob,
          originalCnImage: CNImageBlob,
        });
        //now handle base image and upload
        CallAwsUrl((res) => {
          handleBaseImageData(
            selectedToolset,
            handleBase64Data(res.data),
            CNImageBlob,
            activeImage?.originalImage,
            activeImage?.initialCNImage
          ); //initialCNImage should be the initial else original if it does not exist
        }, url);
      }, activeImage?.originalImage);
    } else if (isUpscale) {
      //just handle base image and upload
      CallAwsUrl((res) => {
        handleBaseImageData(selectedToolset, handleBase64Data(res.data));
      }, url);
    } else {
      CallAwsUrl((res) => {
        handleBaseImageData(toolset, handleBase64Data(res.data));
      }, url);
    }
  };

  const handleBaseImageData = (
    toolset,
    imageUrl,
    CNImageBlob = null,
    CNImageUrl = null,
    initialCNImageUrl = null
  ) => {
    let bool = false;
    const img = new Image();
    img.src = imageUrl;
    const activeToolset = JSON.parse(
      localStorage.getItem(`activeToolset_${lastPart}`)
    );
    const selection = localStorage.getItem(`selection_${lastPart}`);
    img.onload = function () {
      let resizedWidth, resizedHeight, scale;
      let finalResizedImageUrl = processImage(
        img,
        computePostProcessedDimension,
        toolset,
        (dimensions) => {
          resizedWidth = dimensions.resizedWidth;
          resizedHeight = dimensions.resizedHeight;
          scale = dimensions.scale;
        }
      );
      if (scale >= SCALE_LIMIT) {
        setIsUploadScaled(true);
      }
      const proceedNextStep = () => {
        fetch(finalResizedImageUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const resizedImageFile = new File([blob], "resized_image.jpeg", {
              type: "image/jpeg",
            });

            uploadFile(
              "reference-image",
              bool,
              resizedImageFile,
              async (data) => {
                switch (toolset) {
                  case 0:
                  case 1:
                    updateRenderToolsetData({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const renderUpdate = {
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: data?.path,
                    };
                    localStorage.setItem(
                      `designBaseimg_${lastPart}`,
                      JSON.stringify(renderUpdate)
                    );
                    break;
                  case 2:
                    defaultMask = await maskCreationPromise;
                    resetMask(0);
                    updateEditToolsetData({
                      isFromDesignActivated: CNImageUrl ? true : false,
                      feedImageBase64: data,
                      uploadedBaseImage: finalResizedImageUrl,
                      maskImageData: defaultMask.visualizedMaskBase64,
                      maskImageBase64: defaultMask.actualMaskData,
                      cnImageBase64: CNImageUrl ? { path: CNImageUrl } : null, //current cn should just be last cn
                      originalCnImageBase64: CNImageUrl
                        ? { path: initialCNImageUrl || CNImageUrl }
                        : null, //but original should be initial cn if it exists
                    });
                    break;
                  case 3:
                    updateUpscaleToolsetData({
                      feedUpBase64: data,
                      isUpScaleURLImgUrl: finalResizedImageUrl,
                    });
                    const upscaleUpdate = {
                      feedUpBase64: data,
                      isUpScaleURLImgUrl: data?.path,
                    };
                    localStorage.setItem(
                      `upscaleBaseimg_${lastPart}`,
                      JSON.stringify(upscaleUpdate)
                    );
                    break;
                  case 10:
                    updateEditToolsetData({
                      isFromDesignActivated: true,
                      cnImage: finalResizedImageUrl,
                      cnImageBase64: data,
                      originalCnImageBase64: data,
                      maskImageData: defaultMask.visualizedMaskBase64,
                      // maskImageBase64: defaultMask.actualMaskData,//no need because this always happens after toolset == 2, meaning there will always be a default mask
                    });
                    break;
                  case 11:
                    updateAtmosphereRefData({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const check = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check[0]) {
                      check[0].feedDesignBase64 = data;
                      check[0].uploadedBaseImageUrl = data?.path;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check)
                    );
                    break;
                  case 12:
                    updateAtmosphereRefData({
                      feedDesignBase64: data,
                      uploadedBaseImageUrl: finalResizedImageUrl,
                    });
                    const check1 = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check1[1]) {
                      check1[1].feedDesignBase64 = data;
                      check1[1].uploadedBaseImageUrl = data?.path;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check1)
                    );
                    break;
                  default:
                    break;
                }
              },
              () => setIsButtonDisabled(false),
              (error) => {
                handleRateLimitPopup(error);
                switch (toolset) {
                  case 0:
                  case 1:
                    updateRenderToolsetData({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    const renderUpdate = {
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    };
                    localStorage.setItem(
                      `designBaseimg_${lastPart}`,
                      JSON.stringify(renderUpdate)
                    );
                    break;
                  case 2:
                    defaultMask = null;
                    resetMask(0);
                    updateEditToolsetData({
                      isFromDesignActivated:
                        activeToolset?.toolsetActive === "2",
                      feedImageBase64: null,
                      uploadedBaseImage: null,
                      maskImageData: null,
                      maskImageBase64: null,
                      cnImageBase64: null,
                      originalCnImageBase64: null,
                    });
                    break;
                  case 3:
                    updateUpscaleToolsetData({
                      feedUpBase64: null,
                      isUpScaleURLImgUrl: null,
                    });
                    const upscaleUpdate = {
                      feedUpBase64: null,
                      isUpScaleURLImgUrl: null,
                    };
                    localStorage.setItem(
                      `upscaleBaseimg_${lastPart}`,
                      JSON.stringify(upscaleUpdate)
                    );
                    break;
                  case 10:
                    updateEditToolsetData({
                      isFromDesignActivated: true,
                      cnImage: null,
                      cnImageBase64: null,
                      originalCnImageBase64: null,
                      maskImageData: null,
                    });
                    break;
                  case 11:
                    updateAtmosphereRefData({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    const check = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check[0]) {
                      check[0].feedDesignBase64 = null;
                      check[0].uploadedBaseImageUrl = null;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check)
                    );
                    break;
                  case 12:
                    updateAtmosphereRefData({
                      feedDesignBase64: null,
                      uploadedBaseImageUrl: null,
                    });
                    const check1 = JSON.parse(
                      localStorage.getItem(`atmosphereRefLocal_${lastPart}`)
                    );
                    if (check1[1]) {
                      check1[1].feedDesignBase64 = null;
                      check1[1].uploadedBaseImageUrl = null;
                    }
                    localStorage.setItem(
                      `atmosphereRefLocal_${lastPart}`,
                      JSON.stringify(check1)
                    );
                    break;
                  default:
                    break;
                }
              },
              updateSocketData
            );
          })
          .catch((error) => {
            console.error("Error converting Data URL to File:", error);
          });
      };

      const maskCreationPromise =
        toolset === 2
          ? createDefaultMask(
              finalResizedImageUrl,
              resizedWidth,
              resizedHeight,
              CNImageBlob,
              selection === "2",
              updateSocketData
            )
          : null;

      //if its later upload, sizes of the images might not be the same, needs heavy image manipulation therefore need to go through this way
      let defaultMask = null;
      if (toolset === 10) {
        createMaskWithCNImage(
          editToolsetData.uploadedBaseImage,
          editToolsetData.feedImageBase64.width,
          editToolsetData.feedImageBase64.height,
          finalResizedImageUrl,
          resizedWidth,
          resizedHeight,
          editToolsetData.isFromDesignActivated
        )
          .then((result) => {
            defaultMask = result;
            finalResizedImageUrl = defaultMask.CNImageFittedInsideBG;
            proceedNextStep();
          })
          .catch((error) => {
            console.error("Error in creating mask with CN image:", error);
          });
      } else {
        proceedNextStep();
      }
    };
  };

  const handleDownloadClick = async (activeImageIndex, bool) => {
    const activeImage = projectMedias[activeImageIndex];
    const imageData =
      galleryPopupData !== undefined ? galleryPopupData : activeImage;
    const date =
      galleryPopupData !== undefined
        ? imageData?.created.replace(/\s/g, "")
        : await handleConvertDateTime(imageData?.createdAt);

    const imageName = extractNameFromUrl(imageData?.url);
    const imageGenData =
      galleryPopupData !== undefined
        ? galleryPopupData
        : bool
        ? activeImage
        : projectMedias[activeImageIndex]?.referenceImage[0];
    const updatedLink = imageGenData?.url;

    if (updatedLink) {
      CallAwsUrl((res) => {
        const { status, message, data } = res;
        if (status === 200) {
          const byteCharacters = atob(data);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const isVideo = imageGenData?.toolset === "video";
          const blob = new Blob(byteArrays, {
            type: isVideo ? "video/mp4" : "image/jpeg",
          });
          const url = URL.createObjectURL(blob);
          const downloadName = `${imageData?.username}&${
            imageData?.projectName
          }&${imageData?.generationId}&${imageName}&${date}.${
            isVideo ? "mp4" : "jpeg"
          }`;
          const link = document.createElement("a");
          link.href = url;
          link.download = downloadName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
      }, updatedLink);
    }
    if (galleryPopupData === undefined) {
      closePopup();
      handleRefPopupClose();
    } else {
      handlePopupclose();
      handleRefPopupClose();
    }
  };

  const handleCrossClick = () => {
    closePopup();
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };

  const handleDelete = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsLoader(true);
    const payload = {
      projectId: null,
      mediaIds:
        galleryPopupData !== undefined
          ? [galleryPopupData?.id]
          : [projectMedias[isActiveIndex]?.mediaId],
    };
    if (payload) {
      DeleteImagesGallery((res) => {
        const { status, data, message } = res;
        if (status === 200) {
          setDeletedMediaId((prev) => {
            const mediaId = payload.mediaIds[0];
            if (mediaId !== undefined && !prev.includes(mediaId)) {
              return [...prev, mediaId];
            }
            return prev;
          });
          setConfirmModal(true);
          setIsLoader(false);
          setAlertText(t("Images_deleted_successfully"));
        } else if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        } else {
          setIsLoader(false);
          setConfirmModal(true);
          setAlertText(message);
        }
      }, payload);
    }
  };

  const handleDeleteClose = () => {
    if (galleryPopupData !== undefined) {
      handlePopupclose();
      setConfirmModal(false);
    } else {
      handleCrossClick();
      setConfirmModal(false);
    }
  };

  const deleteTitle = `${t("delete")} 1 ${t("image_text")}`;
  const deleteContent = `${t("delete_the_selected")} 1 ${t("image?")}`;

  return (
    <div
      className="feed"
      onClick={
        galleryPopupData !== undefined ? handlePopupclose : handleCrossClick
      }
    >
      <div
        className="feed__popup"
        onClick={
          galleryPopupData !== undefined ? handlePopupclose : handleCrossClick
        }
      >
        <div className="feed__popup__main">
          <div
            className="feed__popup__container"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="feed__popup__section">
              <CustomSlider
                projectMedias={projectMedias}
                onDownloadClick={handleDownloadClick}
                onUpdateBaseImage={handleUpdateBaseImage}
                onUpdateRefrenceImage={handleUpdateRefrenceImage}
                onUpscaleBaseImage={handleUpscaleClick}
                onCloseClick={handleCrossClick}
                mediaIndex={mediaIndex}
                galleryPopupData={galleryPopupData}
                isGalleryCurrentIndex={isGalleryCurrentIndex}
                handlePopupclose={handlePopupclose}
                handlePopupShowSlider={handlePopupShowSlider}
                popupShowSlider={popupShowSlider}
                popupDynamicValue1={popupDynamicValue1}
                popupDynamicValue2={popupDynamicValue2}
                showStealth={showStealth}
                isGalleryImages={isGalleryImages}
                showAllIcons={showAllIcons}
                handleDeletePopup={handleDeletePopup}
                setIsActiveIndex={setIsActiveIndex}
                isFavoriteImages={isFavoriteImages}
                setIsFavoriteImages={setIsFavoriteImages}
                isLoader={isLoader}
                handleRefPopup={handleRefPopup}
                handleRefPopupClose={handleRefPopupClose}
                setTemplateDisable={setTemplateDisable}
                showDownloadIcon={showDownloadIcon}
                showDeleteIcon={showDeleteIcon}
                handleWorkspacePopupUp={handleWorkspacePopupUp}
                handleWorkspacePopupDown={handleWorkspacePopupDown}
                generatedAllImages={generatedAllImages}
                generationIndex={generationIndex}
                setHighlightedMediaId={setHighlightedMediaId}
                setGalleryPopupData={setGalleryPopupData}
                setIsGalleryCurrentIndex={setIsGalleryCurrentIndex}
                isWorkspaceUpAvailable={isWorkspaceUpAvailable}
                isWorkspaceDownAvailable={isWorkspaceDownAvailable}
              />
            </div>
            {isDeletePopup && (
              <Confirm
                isCancelRequired={true}
                confirmTitle={deleteTitle}
                content={deleteContent}
                onConfirm={handleDelete}
                onCancel={handleDeletePopup}
                cancelButtonText={t("cancel")}
                buttonText={t("delete")}
              />
            )}
            {confirmModal && (
              <Confirm
                buttonText={"OK"}
                isCancelRequired={false}
                confirmTitle={alertText}
                onConfirm={handleDeleteClose}
                onCancel={handleDeleteClose}
              />
            )}
            {refPopup && (
              <WorkspaceFeedRefPopup
                onCloseClick={handleRefPopup}
                handlePopupclose={handleRefPopup}
                galleryPopupData={galleryPopupData}
                showAllIcons={showAllIcons}
                activeIndex={mediaIndex}
                isLoader={isLoader}
                onDownloadClick={handleDownloadClick}
                onUpdateBaseImage={handleUpdateBaseImage}
                onUpdateRefrenceImage={handleUpdateRefrenceImage}
                onUpscaleBaseImage={handleUpscaleClick}
                projectMedias={projectMedias}
              />
            )}
          </div>
        </div>
        {isLoader && (
          <div className="click-load">
            <div className="click-load__container">
              <img src={load} alt="load" />
            </div>
          </div>
        )}
        {loader && (
          <div className="fav-container">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkSpaceFeedPopUp;
