import React, { useContext } from "react";
import "./TokenExpirePopup.scss";
import info from "../../assets/images/icons/alert-icon.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserSocketContext } from "../../store/UserSocketData";

const TokenExpirePopup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateSocketData } = useContext(UserSocketContext);

  const handleExit = () => {
    updateSocketData({
      isTokenExpirePopup: false,
      isSocketClose: true,
    });
    const plugins = JSON.parse(localStorage.getItem("plugin"));
    const getOldVersion = localStorage.getItem("currentVersion");
    const lang = localStorage.getItem("language");
    localStorage.clear();
    localStorage.setItem("plugin", JSON.stringify(plugins));
    localStorage.setItem("currentVersion", getOldVersion);
    localStorage.setItem("language", lang);
    navigate("/sign-in", { replace: true });
    window.location.reload();
  };

  return (
    <div className="expire-popup" onClick={handleExit}>
      <div className="expire-popup__container">
        <div className="expire-popup__sections">
          <div className="expire-popup__sections__header">
            <img src={info} alt="info-icon" />
            <h3>{t("token_expiry_heading")}</h3>
          </div>
          <div className="expire-popup__sections__content">
            <p>{t("token_expiry_content")}</p>
            <p>{t("token_expiry_content1")}</p>
          </div>
          <div className="expire-popup__sections__buttons">
            <button onClick={handleExit}>{t("ok")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenExpirePopup;
